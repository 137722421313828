import React, { useContext, useEffect, useRef, useState } from "react";
import useGoldMedal from "../../hooks//useGoldMedal";
import styles from "./tabs.module.less";

interface IProps {
  activeIndex: number;
  list: any[];
  onChange: (number) => void;
}

const Tabs: React.FC<IProps> = ({ list, activeIndex, onChange }) => {

  // const {
  //   tabList,
  //   tabIndex,
  //   setTabIndex
  // } = useGoldMedal({});

  return (
    <div className={styles.tabs}>
      {list.map((item, index) => {
        return (
          <div
            key={index}
            className={styles.tab__item + " " + (activeIndex === index ? styles.active : "")}
            onClick={() => onChange(index)}
          >
            {item.name}
            {/* {item?.total > 0 && <div className={styles.badge} data-label={item.total > 99 ? "99+" : item.total}></div>} */}
          </div>
        );
      })}
    </div>
  )
}

export default Tabs;