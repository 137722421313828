import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import styles from "./index.module.less";
// import { Swiper, Image } from "antd-mobile";
import { pxtorem } from "../../utils/pxtorem";
// import useNavigation from "@/utils/useNavigate";
// import { formatImgUrl } from "@/utils/crop";
import exposure from "../../utils/expose/exposure";
interface Props {
  appStore?: any;
  propData?: any;
  pageData?: any;
  resourceRank?: any;
  navType?: any;
  navResourceRank?: any;
  [key: string]: any;
}

const TextCom: React.FC<Props> = (props: Props) => {
  // const { preasableAction } = useNavigation();

  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      const dom = document.getElementById(`${props.propData.componentCode}`);
      if (dom) {
        exposure.add({
          el: dom,
        });
      } else {
        flag = true;
      }
      if (!flag) {
        clearInterval(interval);
      }
    }, 1000);
  }, []);

  console.log(props.propData.text);

  return (
    <div
      className={styles.text}
      style={{
        padding: `${pxtorem(props.propData?.paddingTop)} ${pxtorem(
          props.propData?.paddingRight,
        )} ${pxtorem(props.propData?.paddingBottom)} ${pxtorem(props.propData?.paddingLeft)}`,
      }}
      id={`${props.propData.componentCode}`}
      data-param={JSON.stringify({
        type: props.navType ? (props.navType === "normal" ? "普通导航" : "电梯导航") : "文本",
        content: "文本",
        picture: true,
        hasSpecially: false,
        resourceRank: props.navResourceRank >= 0 ? props.navResourceRank : props.resourceRank,
        relativeRank: 0,
      })}
      data-eventid={props.propData.componentCode}
    >
      <div dangerouslySetInnerHTML={{ __html: props.propData.text }}></div>
    </div>
  );
};

export default inject("appStore")(observer(TextCom));
