import { formatTimeAgo } from "@/pages/promote-detail/utils/utils";
import { formatImgUrl } from "@/utils/crop";
import { pxtorem } from "@/utils/pxtorem";
import { Image } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";

interface CoverVOProps {
  coverUrl: string;
  vedioUrl?: string;
}
export interface MaterialCardProps {
  //标题
  name: string;
  //作者
  creatorNickname: any;
  //点赞数
  awesomeNum?: string;
  // 图文还是视频
  containVideo: boolean;
  // 是否点赞
  awesome: boolean;
  // 作者头像
  creatorAvatar: string;
  // 素材图片
  coverVOList: Array<CoverVOProps>;
  coverInfo: any;
  // 是否来自游学
  source: number;
  goDetail: (item: any) => void;
  setLike: (item: any) => void;
  // 其他选项为任意类型
  [key: string]: any;
}
const videoIcon =
  "https://akim-oss.aikucun.com/e31dfec97b5c8a5ec8cf97faf0f267f6c6e0e57f_1700124380032_87.png";
const likeIcon =
  "https://akim-oss.aikucun.com/8545980b4af189ab1f4be10e3b100a319cedaf99_1700125228030_27.png";
const unLikeIcon =
  "https://akim-oss.aikucun.com/b46c103bc931434344b044e0105193ca79ee1dbd_1700125278816_8.png";
const labelIcon =
  "https://akim-oss.aikucun.com/38a80481bf2c1772fab0d1f35fd7f815a6685387_1702458140350_39.png";
const MaterialCard: React.FC<MaterialCardProps> = (props) => {
  const {
    name,
    awesomeNum,
    containVideo,
    awesome,
    coverVOList,
    goDetail,
    setLike,
    source,
    coverInfo,
    createTimeStamp,
    sysTime,
  } = props;
  const timeFormat = formatTimeAgo(createTimeStamp, sysTime);
  const coverWidth = 172;
  const coverHeight = (coverInfo?.height / coverInfo?.width) * 172;
  return (
    <div
      className={styles.material_card}
      onClick={(e) => {
        e.stopPropagation();
        goDetail(props);
      }}
    >
      {containVideo && <img className={styles.video_icon} src={videoIcon} />}
      <Image
        src={formatImgUrl(coverVOList[0]?.coverUrl, coverWidth, coverHeight, "mfit", false)}
        lazy={true}
        style={{ width: pxtorem(coverWidth), height: pxtorem(coverHeight) }}
        className={styles.material_pic}
      />
      <div className={styles.content}>
        {source === 2 && <img src={labelIcon} className={styles.label} />}
        <span style={{ textIndent: source === 2 ? pxtorem(32) : 0 }} className={styles.title}>
          {name}
        </span>
      </div>
      <div className={styles.sub_content}>
        {/* <div className={styles.authpic_wrapper} >
          <img src={creatorAvatar} className={styles.author_pic} />
        </div>
        <div className={styles.author}>{timeFormat}</div> */}
        <div className={styles.author}>{timeFormat}</div>
        <div
          className={styles.like_wrapper}
          onClick={(e) => {
            e.stopPropagation();
            setLike(props);
          }}
        >
          <img src={awesome ? likeIcon : unLikeIcon} className={styles.like_pic} />
          <span className={styles.like_num}>{awesomeNum}</span>
        </div>
      </div>
    </div>
  );
};
export default MaterialCard;
