import { Image } from "antd-mobile";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import morePng from "../../assets/more.png";
import { CheckTime, initTime } from "../../utils/countdown";
import { pxtorem } from "../../utils/pxtorem";
import styles from "./index.module.less";
// import rightIcon from "@/assets/right-icon.png";
import moreProductPng from "@/assets/more_product.png";
import nullPng from "@/assets/null.png";
import playPng from "@/assets/play.png";
import { activityAddCalendar } from "@/utils/actions";
import { formatImgUrl } from "@/utils/crop";
import { platform } from "@/utils/platform";
import { project } from "@/utils/project";
import useNavigation from "@/utils/useNavigate";
import exposure from "../../utils/expose/exposure";
import SaveMoney from "./SaveMoney";
interface Props {
  appStore?: any;
  propData?: any;
  pageData?: any;
  resourceRank?: any;
  last?: boolean;
  loadMore?: any;
  isNav?: boolean;
  navType?: any;
  direction?: any;
  navResourceRank?: any;
  trackInfo?: any;
  [key: string]: any;
}

const ActivityCom: React.FC<Props> = (props: Props) => {
  // const [flag, setFlag] = useState(false);
  const { navigate2Product, navigate2Activity, navigate2LiveRoom } = useNavigation();
  const [dataInfo, setDataInfo] = useState({ ...props.propData });
  const changeTime = () => {
    for (const item of dataInfo?.dataInfo?.result || []) {
      const { day, hour, minute, seconds } = initTime({
        type: "ms",
        startTime: item?.status === 1 ? item?.beginTimeStr : item?.endTimeStr,
        serverTime: new Date().valueOf(),
      });
      item.day = day;
      item.hour = hour;
      item.minute = minute;
      item.seconds = seconds;
    }
    setDataInfo({ ...dataInfo });
  };
  const TimeLabel = ({ item }) => {
    const { day, hour, minute, seconds } = initTime({
      type: "ms",
      startTime: item?.status === 1 ? item?.beginTimeStr : item?.endTimeStr,
      serverTime: new Date().valueOf(),
    });
    const decorate = location.href.indexOf("/decorate") >= 0;
    if (
      (day > 0 || hour > 0 || minute > 0 || seconds > 0) &&
      item.activityType !== 3 &&
      (item?.status === 1 || day < 10)
    ) {
      return (
        <div
          className={styles.countdown}
          style={{ bottom: decorate ? pxtorem(-27) : pxtorem(-23) }}
        >
          <div className={styles.pad_4}>距{item?.status === 1 ? "开始" : "结束"}</div>
          {day > 0 ? (
            <>
              <div className={styles.color_block}>{CheckTime(day)}</div>
              <div className={styles.pad_4}>天</div>
            </>
          ) : (
            <>
              <div className={styles.color_block}>{CheckTime(hour)}</div>
              <div className={styles.pad_4}>时</div>
              <div className={styles.color_block}>
                {minute > 0 ? CheckTime(minute) : seconds > 0 ? "01" : 0}
              </div>
              <div className={styles.pad_4}>分</div>
            </>
          )}
        </div>
      );
    }

    if (Number(item.activityType) === 3 && Number(item.status) === 2) {
      return <div className={styles.process}>档期进行中</div>;
    }

    return <></>;
  };
  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < props.propData?.dataInfo?.result?.length; i++) {
        const dom = document.getElementById(
          `${props.propData.componentCode}-${props.propData.dataInfo?.result[i].activityId}`,
        );
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
      }
      if (!flag) {
        clearInterval(interval);
      }
    }, 1000);
  }, [props.propData?.dataInfo?.result]);

  useEffect(() => {
    changeTime();
  }, []);

  const getDecimal = (marketingPrice) => {
    const decimal = marketingPrice.toString().split(".")[1];
    if (decimal) {
      return <div className={styles.decimal}>.{decimal}</div>;
    }
    return "";
  };

  //跳转商品
  const goProduct = (e, product, item, index) => {
    e.stopPropagation();
    navigate2Product(
      {
        activitySpuId: product.activitySpuId,
        activityId: product.activityId,
        selectSkuId: product.selectSkuId,
        marketName: props.pageData.title,
      },
      {
        type: props.navType ? (props.navType === "normal" ? "普通导航" : "电梯导航") : "档期",
        targetId: product.activitySpuId,
        resourceRank: props.navResourceRank >= 0 ? props.navResourceRank : props.resourceRank,
        relativeRank: index,
        hasSpecially: false,
      },
    );
  };

  //跳转会场
  const goConference = (e, item, index?: any, type?: any) => {
    e.stopPropagation();
    let btnInfo: any = null;
    if (type === "btn") {
      btnInfo = {
        page_name: document.title,
        module: "档期",
        btn_name: "进入会场",
        btn_text: "进入会场",
      };
    } else if (type === "more") {
      btnInfo = {
        page_name: document.title,
        module: "档期",
        btn_name: "更多商品",
        btn_text: "更多商品",
      };
    }
    navigate2Activity(
      {
        activityNo: item.activityId,
        marketName: props.pageData.pageCode,
      },
      {
        type: props.navType ? (props.navType === "normal" ? "普通导航" : "电梯导航") : "档期",
        targetId: item.activityId,
        resourceRank: props.navResourceRank >= 0 ? props.navResourceRank : props.resourceRank,
        relativeRank: index,
        hasSpecially: false,
        btnInfo: btnInfo,
        ...props.trackInfo,
      },
    );
  };

  //跳转直播间
  const goLive = (e, item) => {
    e.stopPropagation();
    navigate2LiveRoom({
      liveId: item.liveVO.liveNo,
      liveStatus: item.liveVO.liveStatus,
    });
  };
  //标签
  const formatLabel = (list, type) => {
    if ((list?.length ?? 0) <= 0) {
      return [];
    }
    const arr: any = [];
    for (const label of list) {
      if (label.locationCode === type && label?.labelInfoVOList?.length > 0) {
        for (const labelList of label.labelInfoVOList) {
          // if (type !== "H5_ScheduleList_BrandDown" && arr.length > 0) {
          //   break;
          // }
          arr.push({
            type: labelList.contentType,
            value: labelList.labelIconUrl ? labelList.labelIconUrl : labelList.labelText,
            height: 15,
            width: labelList.labelIconWidth / (labelList.labelIconHeight / 15),
          });
        }
      }
    }
    return arr;
  };
  const BrandDownLabel = (props) => {
    const { item, className } = props;
    if (location.href.indexOf("/decorate") >= 0) {
      return (
        <div className={`${styles.discount_label} ${className}`}>
          {formatLabel(item?.labelLocationVOList, "H5_Shop_ScheduleList_BrandDown").map(
            (label, labelIndex) => {
              if (label.type === "image") {
                return (
                  <Image
                    key={labelIndex}
                    src={label.value}
                    style={{
                      height: pxtorem(16),
                      width: pxtorem(label.width),
                      paddingRight: pxtorem(4),
                      paddingTop: pxtorem(8),
                    }}
                  ></Image>
                );
              }
              if (label.type === "text") {
                return (
                  <div
                    key={labelIndex}
                    style={{
                      paddingRight: pxtorem(4),
                    }}
                  >
                    {label.value}
                  </div>
                );
              }
            },
          )}
        </div>
      );
    } else {
      return (
        <>
          {formatLabel(
            item?.labelLocationVOList,
            project() === 2
              ? "H5_Shop_ScheduleList_BrandDown"
              : "H5_SuperMarketing_ActivityStyleNo1_BrandDown",
          ).map((label, labelIndex) => {
            if (label.type === "image") {
              return (
                <Image
                  key={labelIndex}
                  src={label.value}
                  style={{
                    height: pxtorem(16),
                    width: pxtorem(label.width),
                    paddingRight: pxtorem(4),
                    paddingTop: pxtorem(8),
                  }}
                ></Image>
              );
            }
            if (label.type === "text") {
              return (
                <div
                  key={labelIndex}
                  style={{
                    paddingRight: pxtorem(4),
                  }}
                >
                  {label.value}
                </div>
              );
            }
          })}
        </>
      );
    }
  };

  return (
    <>
      {props.propData.dataInfo?.result?.length > 0 &&
        props.propData.dataInfo?.result?.map((item, index) => {
          return (
            <div
              key={index}
              className={styles.activity}
              style={{
                margin: `${pxtorem(props.propData?.paddingTop)} ${pxtorem(
                  props.isNav ? 0 : 12,
                )} ${pxtorem(props.propData?.paddingBottom)} ${pxtorem(props.isNav ? 0 : 12)}`,
                marginBottom: props.isNav ? pxtorem(6) : pxtorem(props.propData?.paddingBottom),
              }}
              id={`${props.propData.componentCode}-${item.activityId}`}
              data-param={JSON.stringify({
                type: props.navType
                  ? props.navType === "normal"
                    ? "普通导航"
                    : "电梯导航"
                  : "档期",
                content: "档期",
                name: item.activityName,
                id: item.activityId,
                picture: true,
                hasSpecially: false,
                activityId: item.activityId,
                resourceRank:
                  props.navResourceRank >= 0 ? props.navResourceRank : props.resourceRank,
                relativeRank: index,
                ...props.trackInfo,
              })}
              data-eventid={props.propData.componentCode + "-" + item.activityId}
              onClick={(e) => goConference(e, item, index)}
            >
              <div className={styles.top}>
                <div className={styles.brand}>
                  <img
                    className={styles.img}
                    src={
                      item?.brandLogoUrl
                        ? formatImgUrl(item?.brandLogoUrl, 40, 40, "mfit", false)
                        : nullPng
                    }
                  />
                  <div className={styles.title}>
                    <div
                      className={styles.top_name}
                      style={{
                        maxWidth:
                          props.isNav && props.direction === "VERTICAL"
                            ? platform.isApp && item?.status === 1
                              ? pxtorem(105)
                              : pxtorem(130)
                            : pxtorem(180),
                      }}
                    >
                      {item?.brandName}
                    </div>
                    <div
                      className={styles.bottom_name}
                      style={{
                        maxWidth:
                          props.isNav && props.direction === "VERTICAL"
                            ? platform.isApp && item?.status === 1
                              ? pxtorem(105)
                              : pxtorem(130)
                            : pxtorem(180),
                      }}
                    >
                      {item?.brandStoryVO?.brandIntr}
                    </div>
                  </div>
                </div>
                <div className={styles.time}>
                  <div style={{ display: "flex" }}>
                    {platform.isApp && item?.status === 1 && (
                      <div className={styles.notice} onClick={() => activityAddCalendar(item)}>
                        提醒
                      </div>
                    )}
                    <div
                      className={item?.status === 1 ? styles.btn_pre : styles.btn}
                      onClick={(e) => goConference(e, item, index, "btn")}
                    >
                      <div>进入会场</div>
                      {/* <Image className={styles.icon} src={rightIcon}></Image> */}
                    </div>
                  </div>
                  <TimeLabel item={item} />
                </div>
              </div>
              <div
                className={styles.discount_label}
                style={{
                  padding:
                    location.href.indexOf("/decorate") >= 0
                      ? `0 ${pxtorem(12)} ${pxtorem(8)} 0`
                      : `0 ${pxtorem(12)} 0 ${pxtorem(12)}`,
                  width:
                    location.href.indexOf("/decorate") >= 0
                      ? pxtorem(250)
                      : props.direction === "VERTICAL"
                        ? pxtorem(190)
                        : pxtorem(250),
                }}
              >
                <BrandDownLabel item={item} className={styles.intr_pos} />
              </div>
              <div className={styles.product} onClick={(e) => goConference(e, item, index)}>
                {item?.liveVO &&
                  (item?.liveVO?.liveStatus === 10 || item?.liveVO?.liveStatus === 20) && (
                    <div className={styles.item_block}>
                      <div
                        className={styles.item}
                        style={{
                          background: `url(${formatImgUrl(
                            item?.liveVO?.coverUrl,
                            100,
                            100,
                            "mfit",
                            false,
                          )}) no-repeat `,
                          backgroundSize: "100% 100%",
                          backgroundColor: " #ddd",
                          overflow: "hidden",
                        }}
                        onClick={(e) => goLive(e, item)}
                      >
                        <div className={styles.live}>
                          <span className={styles.barTotal}>
                            <span className={styles.bar1 + " " + styles.a1}></span>
                            <span className={styles.bar2 + " " + styles.a2}></span>
                            <span className={styles.bar3 + " " + styles.a3}></span>
                          </span>
                          <div className={styles.text}>{item.liveVO.liveStatusDesc}</div>
                        </div>
                        <div className={styles.liveDesc}>
                          <div className={styles.desc}>{item.liveVO.liveTimeDesc}</div>
                        </div>
                      </div>
                      {!platform.isApp && (
                        <div className={styles.price}>
                          <div className={styles.text}>{item.liveVO.liveTitle}</div>
                        </div>
                      )}
                    </div>
                  )}
                {item?.brandStoryVO &&
                  item?.brandStoryVO?.brandVideoCoverUrl2 &&
                  item?.brandStoryVO?.brandVideo && (
                    <div className={styles.item_block}>
                      <div
                        className={styles.item}
                        style={{
                          background: `url(${
                            item.brandStoryVO.brandVideoCoverUrl2
                              ? formatImgUrl(
                                  item.brandStoryVO.brandVideoCoverUrl2,
                                  100,
                                  100,
                                  "mfit",
                                  false,
                                )
                              : nullPng
                          }) no-repeat `,
                          backgroundSize: "100% 100%",
                          backgroundColor: " #ddd",
                        }}
                        onClick={(e) => goConference(e, item, index)}
                      >
                        <Image className={styles.img_play} src={playPng}></Image>
                      </div>
                      {!platform.isApp && (
                        <div className={styles.story}>
                          <div className={styles.story_text}>
                            <div className={styles.story_text_div}>品牌故事</div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                {(platform.isMini
                  ? item?.productListVOList?.slice(0, 3) || []
                  : item?.productListVOList || []
                ).map((product, proIndex) => {
                  return (
                    <div className={styles.item_block} key={proIndex}>
                      <div
                        className={`${styles.item} ${styles.item_price}`}
                        style={{
                          background: `url(${
                            product?.skuMainImageUrls?.[0]
                              ? formatImgUrl(
                                  product?.skuMainImageUrls?.[0],
                                  100,
                                  100,
                                  "mfit",
                                  false,
                                )
                              : nullPng
                          }) no-repeat`,
                          backgroundSize: "100% 100%",
                          backgroundColor: " #ddd",
                        }}
                        onClick={(e) => goProduct(e, product, item, index)}
                      >
                        {product?.skuCurrentPriceInfo?.commission > 0 &&
                          !(
                            !product.spuSellOut &&
                            product.skuQuantity &&
                            product.skuQuantity > 0
                          ) && (
                            <SaveMoney
                              platform={platform}
                              skuCurrentPriceInfo={product?.skuCurrentPriceInfo}
                            />
                          )}
                        {product.spuSellOut && (
                          <div className={styles.sell_out}>
                            <div className={styles.sell_out_circle}>已售罄</div>
                          </div>
                        )}
                        {!product.spuSellOut && product.skuQuantity && product.skuQuantity > 0 && (
                          <div className={styles.quantity}>仅剩{product.skuQuantity}件</div>
                        )}
                        {formatLabel(
                          product?.labelLocationVOList,
                          project() === 2
                            ? "H5_Shop_ScheduleList_TopProductPicRight"
                            : "H5_SuperMarketing_ActivityStyleNo1_ProductPicRight",
                        ).map((label, labelIndex) => {
                          if (label.type === "image") {
                            return (
                              <Image
                                key={labelIndex}
                                src={label.value}
                                style={{
                                  height: pxtorem(40),
                                  width: pxtorem(40),
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                                fit="contain"
                              ></Image>
                            );
                          }
                          if (label.type === "text") {
                            return (
                              <div
                                key={labelIndex}
                                style={{
                                  height: pxtorem(16),
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                {label.value}
                              </div>
                            );
                          }
                        })}
                        {formatLabel(
                          product?.labelLocationVOList,
                          project() === 2
                            ? "H5_Shop_ScheduleList_TopProductPicLeft"
                            : "H5_SuperMarketing_ActivityStyleNo1_ProductPicLeft",
                        ).map((label, labelIndex) => {
                          if (label.type === "image" && labelIndex === 0) {
                            return (
                              <Image
                                key={labelIndex}
                                src={label.value}
                                style={{
                                  height: pxtorem(16),
                                  width: pxtorem(label.width),
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                }}
                              ></Image>
                            );
                          }
                          if (label.type === "text") {
                            return (
                              <div
                                key={labelIndex}
                                style={{
                                  height: pxtorem(16),
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                {label.value}
                              </div>
                            );
                          }
                        })}
                      </div>
                      {!platform.isApp && (
                        <div className={styles.price}>
                          {(product.skuCurrentPriceInfo.promotionType === "SECKILL" ||
                            product.skuCurrentPriceInfo.promotionType === "FIXED_PRICE") && (
                            <div className={styles.price_text}>
                              {product.skuCurrentPriceInfo.promotionTypeName}
                            </div>
                          )}
                          <div className={styles.price_real} style={{ flexGrow: 0 }}>
                            <div>¥</div>
                            {String(product.skuCurrentPriceInfo?.marketingPrice).length < 5 ? (
                              <>
                                <div className={styles.price_big}>
                                  {product.skuCurrentPriceInfo?.marketingPrice}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className={styles.price_big}>
                                  {Math.trunc(product.skuCurrentPriceInfo?.marketingPrice)}
                                </div>
                                {getDecimal(product.skuCurrentPriceInfo?.marketingPrice)}
                              </>
                            )}
                          </div>
                          {product.skuCurrentPriceInfo.promotionType !== "SECKILL" &&
                            product.skuCurrentPriceInfo.promotionType !== "FIXED_PRICE" && (
                              <div className={styles.price_old}>
                                ¥
                                <span style={{ paddingLeft: pxtorem(2) }}>
                                  {product.skuCurrentPriceInfo?.tagPrice}
                                </span>
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  );
                })}
                {(item?.hasMoreProduct ||
                  (platform.isMini && item?.productListVOList?.length > 3)) && (
                  <div
                    className={styles.item_block}
                    key={index}
                    style={{ paddingRight: pxtorem(6), marginRight: 0 }}
                  >
                    <div
                      className={`${styles.item} ${styles.item_price}`}
                      style={{
                        background: `url(${moreProductPng}) no-repeat`,
                        backgroundSize: "100% 100%",
                        backgroundColor: " #ddd",
                      }}
                      onClick={(e) => goConference(e, item, index, "more")}
                    ></div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      {props.propData.dataInfo?.hasNext && !props.last && (
        <div
          className={styles.more}
          style={{
            margin: `${pxtorem(6)} ${pxtorem(props.propData?.paddingRight)} ${pxtorem(
              props.propData?.paddingBottom,
            )} ${pxtorem(props.propData?.paddingLeft)}`,
          }}
        >
          <div className={styles.content} onClick={props.loadMore}>
            <div className={styles.text}>查看更多</div>
            <Image src={morePng} width={pxtorem(10)} height={pxtorem(10)} fit="fill" />
          </div>
        </div>
      )}
    </>
  );
};

export default inject("appStore")(observer(ActivityCom));
