import { queryUserInfo } from "@/api/user";
import replaceSwiperComp from "@/components/replaceSwiperComp";
import Track from "@/utils/track";
import { CommonContext as TaroCommonContext } from "@akc/mx-taro-h5-components";
import TaroUI, { TaroContextConfig, TaroRole } from "@akc/taro-service";
import { Toast } from "antd-mobile";
import React from "react";
import { platform } from "./platform";
import useNavigation from "./useNavigate";

const useTaroService = () => {
  const {
    navigate2Activity,
    navigate2Product,
    navigate2LiveRoom,
    navigate2Venue,
    navigate2SuperVenue,
    navigate2Shop,
    navigate,
    navigate2H5,
    navigate2ResourceH5,
  } = useNavigation();

  const [taroRole, setTaroRole] = React.useState<TaroRole>(platform.isApp ? "R" : "C");

  const data: {
    profitPrefix: string;
    terminal: TaroContextConfig["terminal"];
  } = {
    profitPrefix: "赚",
    terminal: "xdh5",
  };
  if (platform.isApp) {
    data.profitPrefix = "省赚";
    data.terminal = "akcapp";
  }
  if (platform.isMini) {
    data.profitPrefix = "饷";
    data.terminal = "xdweapp";
  }
  if (platform.isSaas) {
    data.profitPrefix = "赚";
    data.terminal = "saasweapp";
  }

  console.log("----------- taro-service", taroRole, data);

  React.useEffect(() => {
    TaroUI.startService({
      profitPrefix: data.profitPrefix,
      terminal: data.terminal,
      track: {
        pageView: (props: { pageName: string; properties: Record<string, any> }) => {
          const track = Track();
          track.pageView(props.pageName, props.properties);
        },
        action: (props: { actionName: string; properties: Record<string, any> }) => {
          const track = Track();
          track.track(props.actionName, props.properties);
        },
      },
      navigate: {
        toProduct: (props) =>
          navigate2Product({
            activitySpuId: props.activitySpuId,
            activityId: props.activityId || "",
            firstSalePropertyValue: props.firstSalePropertyValue,
            selectSkuId: props.selectSkuId,
            marketName: props.marketName,
          }),
        toActivity: (props) => {
          if (platform.isApp || platform.isMini) {
            navigate2Activity({
              activityNo: props.activityId,
            });
            return;
          }
          navigate2H5({ path: `/mx-shop-micro/activity/${props.activityId}` });
        },
        toLive: (props) =>
          navigate2LiveRoom({
            liveId: props.liveId,
          }),
        toVenue: (props) =>
          navigate2Venue({
            miniQuery: { id: props.venueId },
            h5Query: { conferenceId: props.venueId },
          }),
        toSuperVenue: (props) =>
          navigate2SuperVenue({
            miniQuery: { shareOnlyId: props.venueId },
            h5Query: { hotSaleId: props.venueId },
          }),
        toConference3: (props) => {
          navigate({
            appOptions: {
              url: `${origin}/mx-shop-micro?customPageCode=${props.pageCode}`,
            },
            miniOptions: {
              url: `${origin}/mx-shop-micro`,
              query: {
                customPageCode: props.pageCode,
              },
            },
            h5Options: {
              url: `/mx-shop-micro?customPageCode=${props.pageCode}`,
            },
          });
        },
        toShopDetail: (props) =>
          navigate2Shop({
            shopCode: props.shopCode,
          }),
        toH5: (props) => navigate2ResourceH5(props.url),
      },
      ui: {
        showToast: (props) =>
          Toast.show({
            content: props.message,
            duration: (props.duration ?? 2) * 1000,
            maskStyle: { zIndex: 100001 },
          }),
        hideToast: () => Toast.clear(),
      },
      replaceSwiperComp: () => replaceSwiperComp,
    });

    queryUserInfo().then((userInfo) => {
      if (platform.isApp) {
        setTaroRole("R");
        return;
      }
      console.log("----------- taro-service userInfo", userInfo);
      if (userInfo?.currentRole === 4) {
        setTaroRole(userInfo.shopOwner ? "R" : userInfo.shopDistributor ? "E" : "C");
      } else if (userInfo?.currentRole === 3) {
        setTaroRole("E");
      } else if (userInfo?.currentRole === 2) {
        setTaroRole("R");
      } else if (userInfo?.currentRole === 1) {
        setTaroRole("C");
      }
    });
  }, []);

  return {
    ...data,
    taroRole,
  };
};

export const TaroCommonContextProvider = ({ children }: { children: React.ReactNode }) => {
  const context = useTaroService();
  return (
    <TaroCommonContext.Provider
      value={{
        role: context.taroRole,
        profitPrefix: context.profitPrefix,
      }}
    >
      {children}
    </TaroCommonContext.Provider>
  );
};
