import { ActivityTaskItem, ActivityTaskParams } from "@/api/rewardCenter";
import { queryTask } from "@/api/yearEndBonus";
import Track from "@/utils/track";
import constate from "constate";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";

type actSellRebateStatus = 10 | 20 | 30 | 40;

interface tabItem {
  name: string;
  value: actSellRebateStatus;
  total: number;
}

const tabList: tabItem[] = [
  {
    name: "未开始",
    value: 10,
    total: 0,
  },
  {
    name: "进行中",
    value: 20,
    total: 0,
  },
  {
    name: "开奖中",
    value: 30,
    total: 0,
  },
  {
    name: "已开奖",
    value: 40,
    total: 0,
  }
]


const useSearch = () => {
  const track = Track();
  const [search] = useSearchParams();
  const taskAggregationCode = search.get("activityCode") || "";

  const [searchTagText, setSearchTagText] = useState("");
  const [showSearchTag, setShowSearchTag] = useState(true);

  const [historyList, setHistoryList] = useState<any>([]);
  const [showSuggest, setShowSuggest] = useState(false);

  const [inputText, setInputText] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [list, setList] = useState<ActivityTaskItem[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageNum, setPageNum] = useState(1);

  //获取tab下的列表
  const queryList = async (params: ActivityTaskParams) => {
    // if (loading) {
    //   return;
    // }
    setLoading(true);
    const { pageIndex } = params || {};
    let num = pageIndex || pageNum || 1;
    let actList: any = [];
    try {
      if (num === 1) {
        setList([]);
      }
      const data: any = await queryTask({ ...params, pageIndex: num, pageSize: 10, taskAggregationCode });
      actList = data?.result || [];
      if (num > 1 && actList.length > 0) {
        setList(list.concat(actList));
      } else {
        setList(actList);
      }
      setPageNum(num + 1);
      setHasMore(data?.hasNext);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSearch = (data) => {
    // text = text || searchTagText || keyword || "";
    // track.track("btn_click", {
    //   page_name: "店铺聚合",
    //   previous_page_name: search.get("page_name"),
    //   domain: "B2R旗舰店",
    //   btn_name: "搜索",
    //   btn_text: text,
    //   module: "搜索框",
    // });
    setShowSuggest(false);
    setShowSearchTag(true);
    queryList({
      brandId: data.brandId,
      pageIndex: 1,
    });
  };

  const handleClickKeyTag = (data: any) => {
    console.log("handleClickKeyTag", data);
    track.track("resource_click", {
      page_name: "奖励中心搜索结果",
      previous_page_name: search.get("page_name"),
      domain: "激励活动",
      resource_name: data.brandName,
      btn_name: "点击品牌",
    });
    setShowSuggest(false);
    setShowSearchTag(true);
    setSearchTagText(data.brandName);
    handleSearch(data);
  };

  const handleClearHistory = () => {
    localStorage.removeItem("keywords");
    setHistoryList([]);
  };

  // 加载更多
  const loadMore = async () => {
    // if (hasMore) {
    //   await queryList({ ...(query || {}) });
    // }
  };

  useEffect(() => {
    queryList({
      actSellRebateStatus: tabList[tabIndex].value,
      pageIndex: 1,
    });
  }, [tabIndex]);

  useEffect(() => {
    if (showSuggest) { return }
    queryList({
      actSellRebateStatus: tabList[tabIndex].value,
      pageIndex: 1,
    });
  }, [showSuggest]);

  useEffect(() => {
    let list: any = localStorage.getItem("keywords");
    if (!isEmpty(list)) {
      list = JSON.parse(list);
      list = list.splice(0, 20);
      setHistoryList(list);
    } else {
      setHistoryList([]);
    }
    track.track("$pageview", {
      page_name: "搜索结果页",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
    });
  }, []);

  useActivate(() => {
    console.log("in useActivate search");
    document.title = "\u200E";
    let list: any = localStorage.getItem("keywords");
    if (!isEmpty(list)) {
      list = JSON.parse(list);
      list = list.splice(0, 20);
      setHistoryList(list);
    } else {
      setHistoryList([]);
    }
  });

  return {
    searchTagText,
    showSearchTag,

    historyList,
    showSuggest,

    setShowSearchTag,
    setShowSuggest,
    setSearchTagText,

    handleSearch,

    handleClearHistory,
    handleClickKeyTag,

    inputText,
    setInputText,
    tabList,
    tabIndex,
    setTabIndex,
    list,
    loading,
    hasMore,
    pageNum,
    loadMore
  };
};

export const [SearchProvider, useSearchContext] = constate(useSearch);
