// polyfill 解决兼容性问题
import stores from "@/store/index";
import Track from "@/utils/track";
import { getUrlParam } from "@/utils/url-parse";
import "intersection-observer";

const track = Track();

// 延迟时间，节流作用
IntersectionObserver.prototype["THROTTLE_TIMEOUT"] = 300;

class Exposure {
  constructor(maxNum = 10) {
    this.dataList = [];
    this.maxNum = maxNum;
    this.time = 0; // 延迟上报时间
    this.dataList = [];
    this.maxNum = maxNum; // 一次上报最大个数
    this.observerInstance = null;

    this.init();
  }

  // 初始化
  init() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.observerInstance = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio < 0.5) {
            self.dataList = self.dataList.filter(
              (item) => item.eventId !== entry.target.attributes["data-eventid"]?.value,
            );
            return;
          }
          setTimeout(() => {
            self.send();
            clearTimeout();
          }, 500);
          let eventParam = entry.target.attributes["data-param"]?.value;
          if (eventParam) {
            eventParam = JSON.parse(eventParam);
            const eventId = entry.target.attributes["data-eventid"]?.value;
            self.dataList.push({ eventId, eventParam });
          }
        });
      },
      {
        root: document.querySelector("#root"),
        rootMargin: "0px",
        threshold: 0.5, // 目标dom出现在视图的比例 0 - 1
      },
    );
  }

  // 添加至观察列表
  add(entry) {
    const { el } = entry || {};
    this.observerInstance && this.observerInstance.observe(el);
  }

  // 触发上报数据
  send() {
    // const list = [];
    // for (const item of this.dataList) {
    //   let flag = false;
    //   for (const send of stores.appStore.removeList) {
    //     if (item.eventId == send) {
    //       flag = true;
    //     }
    //   }
    //   if (!flag) {
    //     list.push(item);
    //   }
    // }
    const data = this.dataList.slice(0, this.maxNum);
    for (const item of data) {
      stores.appStore.removeList.push(item.eventId);
      //埋点
      /* eslint-disable */
      if (item.eventId.indexOf("-nav") >= 0) {
        track.track("tab_expose", {
          page_name: document.title,
          tab_name: item.eventParam.tab_name,
          rank: Number(item.eventParam.rank),
          market_id: getUrlParam()?.["customPageCode"],
          market_name: document.title,
        });
      } else if (item.eventId.indexOf("market-") >= 0) {
        track.track("resource_expose", {
          ...item.eventParam,
          previous_page_name: getUrlParam()?.["page_name"],
        });
      } else {
        track.track("resource_expose", {
          page_name: item.eventParam?.page_name || document.title,
          resource_type: item.eventParam.type,
          resource_content: item.eventParam.content,
          resource_name: item.eventParam.name,
          resource_id: item.eventParam?.id,
          resource_rank: item.eventParam?.resourceRank,
          relative_rank: item.eventParam?.relativeRank,
          has_picture: item.eventParam?.picture,
          has_specially: item.eventParam?.hasSpecially,
          activity_spu_code: item.eventParam?.activitySpuId,
          activity_id: item.eventParam?.activityId,
          market_id: getUrlParam()?.["customPageCode"],
          market_name: document.title,
          coupons_id: item.eventParam?.couponsId,
          desc: getUrlParam()?.["merchantShopCode"],
          tab_name: item.eventParam.tabName,
          material_id: item.eventParam?.materialId,
          search_request_id: item.eventParam?.searchRequestId,
          domain: item.eventParam?.domain,
          module: item.eventParam?.module,
          click_material_id: item.eventParam?.click_material_id,
          previous_page_name: item.eventParam?.previous_page_name || getUrlParam()?.["page_name"],
          ...item.eventParam,
        });
      }
      /* eslint-enable */
    }
    this.dataList = [];
  }

  // 组件销毁，数据全部上报
  // beforeUnmount() {
  //   const data = this.dataList;
  // }
}

export default new Exposure();
