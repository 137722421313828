import iconCard from "@/assets/icon_card.png";
import iconLink from "@/assets/icon_link.png";
import iconWritting from "@/assets/icon_writting.png";
import { ImageViewer, Toast } from "antd-mobile";
import clsx from "clsx";
import React, { FC, ReactNode, useContext, useEffect, useRef, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./index.module.less";
import { calcImgWidthOptions } from "./util";
// import useNavigate from "@/utils/useNavigate";
import { fetchCopyLink } from "@/api/scode/copy_link";
import { fetchCopyWriting } from "@/api/scode/copy_writing";
import { PosterInfo, generateSharePoster } from "@/api/scode/poster";
import {
  CarrierElementType,
  ContentType,
  NormalParamsType,
  NormalScodeParams,
  PosterBizInfo,
  bizInfoParams,
  carrierContextParams,
  envContextParams,
} from "@/api/scode/scode_params";
import { useCommonContext } from "@/utils/context/common";
import { platform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import copy from "copy-to-clipboard";
import { useSearchParams } from "react-router-dom";
import ShareOTCBeforeConfirmPopup from "./components/ShareOTCBeforeConfirmPopup";

export type CustomEditData = {
  activitySpuId?: string;
  activityId?: string;
  selectSkuId?: string;
  materialNo?: string;
  actId?: string;
  contentType?: string;
  contentId?: string;
  brandId?: string;
  contentDetailId?: string;
  shareBizType?: string;
  shareMainTitle?: string;
  shareSubTitle?: string;
  sourceScene?: string;
  awardName?: string;
  awardType?: string;
  promoActivityId?: string;
};
interface PosterSwipeProps {
  show: boolean;
  // 海报参数
  posterData?: NormalScodeParams & {
    bizInfo: PosterBizInfo;
  };
  // 海报/复制链接简易参数
  scodeSimpleParams?: {
    // 档期时传入
    activityId?: string;
    // 会场3.0时传入
    conferencePageCode?: string;
    // 商品时传入
    activitySpuId?: string;
    // 品牌id
    brandId?: string;
  } & NormalParamsType;
  // 自定义编辑参数
  customEditData?: CustomEditData;
  // 参照 http://wiki.aikucun.xyz/pages/viewpage.action?pageId=28513120 中的 share_type
  shareType: string;
  sourceScene?: string;
  autoExpose?: boolean;
  lockScroll?: boolean;
  topSlot?: ReactNode;
  actionSlot?: ReactNode;
  btnConfig?: string[];
  change?: (isTrue: boolean) => void;
  close?: () => void;
  edit?: () => void;
  isOTC?: boolean;
}

const PosterSwipe: FC<PosterSwipeProps> = ({
  show,
  posterData: pd,
  scodeSimpleParams,
  customEditData,
  shareType,
  // sourceScene,
  // autoExpose,
  lockScroll = true,
  topSlot,
  actionSlot,
  btnConfig = [],
  change,
  close,
  edit,
  isOTC,
}) => {
  // const { navigate } = useNavigate();
  let imageOptions = calcImgWidthOptions(shareType);
  if (scodeSimpleParams?.contentType === ContentType.activityAggr) {
    imageOptions = calcImgWidthOptions(ContentType.activityAggr);
  }
  const contentType =
    scodeSimpleParams?.contentType ??
    (scodeSimpleParams?.activityId
      ? ContentType.activity
      : scodeSimpleParams?.activitySpuId
        ? ContentType.spuProduct
        : scodeSimpleParams?.conferencePageCode
          ? ContentType.conference3
          : ContentType.activityAggr);
  const contentId =
    scodeSimpleParams?.activityId ??
    scodeSimpleParams?.activitySpuId ??
    scodeSimpleParams?.conferencePageCode ??
    scodeSimpleParams?.brandId;
  const posterData = pd ?? {
    bizInfo: bizInfoParams({
      ...scodeSimpleParams,
      contentType: contentType,
      contentId: contentId,
    }),
    envContext: envContextParams({
      sourceScene: scodeSimpleParams?.sourceScene,
      sourceSceneId: scodeSimpleParams?.sourceSceneId,
    }),
    carrierContext: carrierContextParams(
      scodeSimpleParams?.carrierElements ?? [CarrierElementType.h5Poster],
      scodeSimpleParams?.posterCode,
      scodeSimpleParams?.posterVersion,
    ),
  };
  const { navigate } = useNavigation();

  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [requestId, setRequestId] = useState<string>("");
  const [pictureUrls, setPictureUrls] = useState<PosterInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAbnormal, setIsAbnormal] = useState<boolean>(false);
  const copyMessage = useRef<string>("");
  const copyScode = useRef<string>("");
  const copyWriting = useRef<string>("");
  const { track, currentRole } = useCommonContext();
  const [search] = useSearchParams();
  const shareTabName = posterData?.bizInfo?.contentExtra?.shareTabName;

  // 生成海报
  const genPoster = () => {
    generateSharePoster(posterData).then((res) => {
      const { success, data } = res || {};
      if (success) {
        setLoading(false);
        Toast.clear();
        setPictureUrls(data.posterInfos || []);
      } else {
        setLoading(false);
        Toast.clear();
        setIsAbnormal(true);
        Toast.show("生成海报失败");
      }
    });
  };

  // 分享文案信息
  const genShareMessages = () => {
    if (shareType === "H5_SHOP") {
      return;
    }
    fetchCopyLink({
      ...posterData,
      carrierContext: carrierContextParams([CarrierElementType.linkUrl]),
    }).then((res) => {
      const { success, data } = res || {};
      if (success && data.copyMessage) {
        copyMessage.current = data.copyMessage;
        copyScode.current = data.scode;
      }
    });
  };

  const genWritingMessages = () => {
    if (["ACTIVITY", "SPU_PRODUCT"].includes(contentType) && currentRole !== "C") {
      const { carrierContext, ...params } = posterData;
      fetchCopyWriting({ ...params }).then((res) => {
        const { success, data } = res || {};
        if (success && data?.length) {
          const materialItem = data.filter((item) => item.type === "MATERIAL")[0] || {};
          const aiItem = data.filter((item) => item.type === "AI")[0] || {};
          const defaultItem = data.filter((item) => item.type === "DEFAULT")[0] || {};
          const copyWritings =
            materialItem?.copyWritings || aiItem?.copyWritings || defaultItem?.copyWritings || [];
          copyWriting.current = copyWritings[0] ?? "";
        }
      });
    }
  };

  const initSwiper = () => {
    setLoading(true);
    Toast.show({
      icon: "loading",
      content: "加载中…",
      duration: 0,
    });
    setRequestId(new Date().getTime().toString());
    genPoster();
    genShareMessages();
    genWritingMessages();
  };

  useEffect(() => {
    if (show) {
      if (lockScroll) {
        document.body.style.overflow = "hidden";
      }
      setIsAbnormal(false);
      copyMessage.current = "";
      copyScode.current = "";
      copyWriting.current = "";
      Toast.clear();
      initSwiper();
      setIsConfirm(false);
    }
  }, [show]);

  // 长按事件
  const isPressing = useRef(false);
  // eslint-disable-next-line no-undef
  const isPressingTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const handleLongPress = () => {
    if (isPressing.current) {
      //长按分享
      //复制链接分享
      /* eslint-disable */
      let share_type = shareType;
      let share_method = "二维码";
      let share_channel = "长按保存";
      if (shareTabName) {
        share_type = "STORE_" + shareTabName;
      }
      let page_name = document.title;
      let market_id = search.get("customPageCode");
      let domain = "CONFERENCE3";
      if (shareType === "B2R") {
        page_name =
          customEditData?.sourceScene === "IncentiveActivity" ? "激励活动详情" : "激励活动落地页";
        market_id = search.get("actId");
        domain = "激励活动";
      } else if (shareType === "SALE_CALENDAR") {
        page_name = "今日必卖日历页";
        share_method = "海报";
        share_channel = "保存到相册";
      }
      if (track.track) {
        track.track("$share", {
          share_type,
          share_method,
          share_channel,
          share_setting: "H5",
          scode: copyScode.current,
          is_default: false,
          page_name,
          market_id,
          share_t: new Date().valueOf(),
          market_name: document.title,
          domain,
        });
      }
      /* eslint-enable */
    }
  };
  // 鼠标按下事件处理函数
  const handleMouseDown = () => {
    // 设置一个定时器，在 1 秒后触发长按操作
    isPressingTimeoutId.current = setTimeout(() => {
      isPressing.current = true;
      handleLongPress();
    }, 1000);
  };

  // 鼠标松开事件处理函数
  const handleMouseUp = () => {
    // 清除之前设置的定时器
    isPressingTimeoutId.current && clearTimeout(isPressingTimeoutId.current);
    isPressing.current = false;
  };

  const previewImg = (e: React.MouseEvent<HTMLImageElement>, index: number) => {
    e.stopPropagation();
    const urls: string[] = [];
    pictureUrls.forEach((item, idx) => {
      urls[idx] = item.imageUrl;
    });
    if (platform.isWxH5) {
      window.wx.previewImage({
        current: urls[index],
        urls,
      });
    } else {
      ImageViewer.Multi.show({
        images: urls,
        defaultIndex: index,
      });
    }
  };

  const onClose = () => {
    if (loading) {
      return;
    }
    if (lockScroll) {
      document.body.style.overflow = "hidden";
    }
    setPictureUrls([]);
    change && change(false);
    close && close();
  };

  const handleClose = () => {
    if (lockScroll) {
      document.body.style.overflow = "hidden";
    }
    setPictureUrls([]);
    change && change(false);
    close && close();
  };

  const onCopy = () => {
    if (isAbnormal) {
      Toast.show("海报加载失败，请重新尝试～");
      return;
    }
    if (!copyMessage.current) {
      Toast.show({
        content: "复制失败，请重新生成海报～",
        position: "bottom",
        maskClassName: styles["poster-unify-toast"],
      });
    } else {
      copy(copyMessage.current);
      Toast.show({
        content: "链接已复制",
        position: "bottom",
        maskClassName: styles["poster-unify-toast"],
      });
    }

    //复制链接分享
    let share_type = shareType;
    if (shareTabName) {
      share_type = "STORE_" + shareTabName;
    }
    let page_name = document.title;
    let market_id = search.get("customPageCode");
    let domain = "CONFERENCE3";
    if (shareType === "B2R") {
      page_name =
        customEditData?.sourceScene === "IncentiveActivity" ? "激励活动详情" : "激励活动落地页";
      market_id = search.get("actId");
      domain = "激励活动";
    } else if (shareType === "SALE_CALENDAR") {
      page_name = "今日必卖日历页";
    }
    if (track.track) {
      /* eslint-disable */
      track.track("$share", {
        share_type,
        share_method: "链接",
        share_channel: "复制链接",
        shaer_setting: "H5",
        scode: copyScode.current,
        is_default: false,
        page_name,
        market_id,
        share_t: new Date().valueOf(),
        market_name: document.title,
        domain,
      });
    }
    /* eslint-enable */
  };

  const onEdit = () => {
    if (isAbnormal) {
      Toast.show("海报加载失败，请重新尝试～");
      return;
    }
    onClose();
    const {
      activitySpuId,
      activityId,
      selectSkuId = "",
      materialNo,
      contentType,
      contentId,
      shareBizType,
      shareMainTitle,
      shareSubTitle,
      brandId,
      contentDetailId,
      sourceScene,
      awardName,
      awardType,
      promoActivityId,
    } = customEditData || {};
    const staticUrl = location.protocol + "//" + location.hostname;
    let queryObj: {
      activitySpuId?: string;
      activityId?: string;
      selectSkuId?: string;
      brandId?: string;
      threeClassId?: string;
      isRural?: boolean;
      isTopList?: boolean;
      materialNo?: string;
      shareType?: string;
      queryType?: string | null;
      sharePageId?: string;
      customEditData?: string;
      contentType?: string;
      contentId?: string;
      contentDetailId?: string;
      shareBizType?: string;
      shareMainTitle?: string;
      shareSubTitle?: string;
      sourceScene?: string;
      awardName?: string;
      awardType?: string;
      promoActivityId?: string;
    } = {};
    if (shareType === "ACTIVITY") {
      // 档期或档期全部生成卡片时需要传入 bizInfo.contentExtra.shareActivityId/queryType/sharePageId
      queryObj.activityId = scodeSimpleParams?.activityId ?? scodeSimpleParams?.extra?.activityId;
      queryObj.queryType = scodeSimpleParams?.extra?.queryType;
      queryObj.sharePageId = scodeSimpleParams?.extra?.sharePageId;
    }
    if (activitySpuId && shareType === "PRODUCT") {
      queryObj = {
        activitySpuId: activitySpuId,
        activityId,
        selectSkuId,
      };
    } else if (
      shareType === "ACTIVITY" &&
      scodeSimpleParams?.contentType !== ContentType.activityAggr
    ) {
      queryObj.activityId = scodeSimpleParams?.activityId;
    } else if (
      shareType === "ACTIVITY" &&
      scodeSimpleParams?.contentType === ContentType.activityAggr
    ) {
      queryObj.brandId = scodeSimpleParams.brandId;
      queryObj.shareType = ContentType.activityAggr;
    } else if (shareType === "MATERIAL") {
      queryObj.materialNo = materialNo;
      queryObj.shareType = "MATERIAL3";
    } else if (shareType === "B2R") {
      queryObj = {
        shareType: contentType,
        contentType,
        contentId,
        brandId: brandId || "",
        contentDetailId: contentDetailId || "",
        shareBizType,
        shareMainTitle,
        shareSubTitle,
        sourceScene,
      };
    } else if (shareType === "SALE_CALENDAR") {
      queryObj = {
        shareType: contentType,
        contentType,
        contentId,
      };
    } else if (shareType === "DAILY_AWARD" || shareType === "DAILY_AWARD_GIFT") {
      queryObj.shareType = shareType;
      queryObj.promoActivityId = promoActivityId;
      queryObj.awardName = awardName;
      queryObj.awardType = awardType;
    } else if (shareType === "ANNUAL_BONUS") {
      queryObj = {
        shareType: contentType,
        contentType,
        contentId,
        sourceScene
      }
    }
    let page_name = document.title;
    let market_id = search.get("customPageCode");
    let domain = "";
    if (shareType === "B2R") {
      page_name =
        customEditData?.sourceScene === "IncentiveActivity" ? "激励活动详情" : "激励活动落地页";
      market_id = search.get("actId");
      domain = "激励活动";
    }
    //分享事件前置至按钮上
    /* eslint-disable */
    if (track.track) {
      track.track("$share", {
        share_type: shareType,
        share_method: "转发卡片",
        share_channel: "转发卡片",
        scode: copyScode.current,
        is_default: false,
        page_name,
        market_id,
        share_t: new Date().valueOf(),
        market_name: document.title,
        domain,
      });
    }
    /* eslint-enable */

    // navigate2ResourceH5(`${staticUrl}/#/editForward`, queryObj);
    navigate({
      h5Options: {
        url: "/#/editForward",
        query: queryObj,
      },
    });
    edit && edit();
  };

  const onCopyWriting = () => {
    if (isAbnormal) {
      Toast.show("海报加载失败，请重新尝试～");
      return;
    }
    const ContentNode = (
      <p style={{ textAlign: "center" }}>
        已将转发文案复制到粘贴板
        <br></br>
        好的文案会带来更多的浏览哦～
      </p>
    );
    if (!copyWriting.current) {
      genWritingMessages();
    } else {
      copy(copyWriting.current);
      Toast.show({
        content: ContentNode,
        position: "center",
        maskClassName: styles["poster-unify-toast"],
      });
    }
  };

  const onReload = () => {
    setIsAbnormal(false);
    copyMessage.current = "";
    copyScode.current = "";
    copyWriting.current = "";
    initSwiper();
  };

  return (
    <>
      {show && ["ACTIVITY", "PRODUCT"].includes(shareType) && isOTC && !isConfirm && (
        <ShareOTCBeforeConfirmPopup setIsConfirm={setIsConfirm} handleClose={handleClose} />
      )}
      {show && (!isOTC || (isOTC && isConfirm)) && (
        <div className={styles["poster_swipe_wrapper"]}>
          {!loading && <div className={styles["poster__mask"]} onClick={onClose}></div>}
          {topSlot && topSlot}
          {!loading && (
            <div className={styles["swipe-content"]}>
              {!isAbnormal && (
                <div className={styles["swiper-container"]}>
                  <Swiper
                    className={styles["swiper-wrapper"]}
                    slidesPerView={1.2}
                    centeredSlides
                    spaceBetween={imageOptions.spaceBetween}
                  >
                    {pictureUrls.map((item, index) => (
                      <SwiperSlide key={index} className={styles["swiper-slide"]} onClick={onClose}>
                        <img
                          className={clsx(styles["poster__img"], styles[imageOptions.class])}
                          src={item.imageUrl}
                          data-scode={item.scode}
                          data-reqid={requestId}
                          onError={(e: any) => {
                            e.target.onerror = null;
                            e.target.src =
                              "https://akim-oss.aikucun.com/saas/f378f9726fc41f88df5af93052684fa9cb4ce10c_1639487955668_97.png";
                          }}
                          onClick={(e: React.MouseEvent<HTMLImageElement>) => previewImg(e, index)}
                          onMouseDown={handleMouseDown}
                          onMouseUp={handleMouseUp}
                          onMouseLeave={handleMouseUp}
                          alt=""
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              )}
              {isAbnormal && (
                <div className={styles["poster-abnormal-con"]} style={imageOptions.styles}>
                  <img
                    className={styles["con__img"]}
                    src="https://akim-oss.aikucun.com/06e2474169dc7feeb3bcb4dcc6a0199a0c2c94b2_1668404815589_98.png"
                  />
                  <div className={styles["con__tips"]}>海报加载失败，请重新尝试~</div>
                  <div className={styles["reload-btn"]} onClick={onReload}>
                    重新加载
                  </div>
                </div>
              )}
            </div>
          )}
          {!loading && !isAbnormal && !actionSlot && (
            <div className={styles["poster__tip"]}>
              <img
                className={styles["tip-img"]}
                src="https://akim-oss.aikucun.com/1b4148d1babd98d53b08b3a09f5fe8d460e704e6_1669111122655_99.png"
              />
              长按图片，可转发保存哟~
            </div>
          )}
          {actionSlot && actionSlot}
          {!actionSlot && btnConfig.length > 0 && !loading && !isAbnormal && (
            <div className={clsx(styles["poster__action"], styles["safe--bottom"])}>
              <div className={styles["poster__action__list"]}>
                {btnConfig.length > 0 && btnConfig.includes("copy") && (
                  <div className={styles["poster__action__item"]} onClick={onCopy}>
                    <div className={styles["item-wrapper"]}>
                      <img className={styles["icon"]} src={iconLink} alt="" />
                      <span className={styles["text"]}>复制链接</span>
                    </div>
                  </div>
                )}
                {btnConfig.length > 0 && btnConfig.includes("edit") && (
                  <div className={styles["poster__action__item"]} onClick={onEdit}>
                    <div className={styles["item-wrapper"]}>
                      <img className={styles["icon"]} src={iconCard} alt="" />
                      <span className={styles["text"]}>转发卡片</span>
                    </div>
                  </div>
                )}
                {["ACTIVITY", "SPU_PRODUCT"].includes(contentType) && currentRole !== "C" && (
                  <div className={styles["poster__action__item"]} onClick={onCopyWriting}>
                    <div className={styles["item-wrapper"]}>
                      <img className={styles["icon"]} src={iconWritting} alt="" />
                      <span className={styles["text"]}>复制文案</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PosterSwipe;
