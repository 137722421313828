import stores from "@/store/index";
import { reenterShop } from "@/utils/checkUrl";
import { checkIdentity } from "@/utils/identity";
import { captureException } from "@/utils/sentry";
import { getBaseurl } from "@/utils/url-parse";
import AKJS from "@akc/akjs";
import axios from "axios";
import _ from "lodash";

const getPlatformFunc = () => {
  // eslint-disable-next-line no-underscore-dangle
  const isMini = window.__wxjs_environment === "miniprogram";
  const isApp = navigator && navigator.userAgent.search("AKC") > -1;
  const isSaas = isMini && !!location.href.match(/plt=saas/i);
  const isTuan = isMini && location.href.match(/plt=atuan/i);
  const isWxH5 = !isMini && navigator && !!navigator.userAgent.match(/miniProgram/i);
  const isH5 = isWxH5 || (!isMini && !isApp);
  return {
    isMini,
    isApp,
    isSaas,
    isTuan,
    isH5,
  };
};

const platform = getPlatformFunc();

const getCookie = (cname) => {
  const name = cname + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
};

export function getQueryParams(url: string) {
  const paramArr = url.slice(url.indexOf("?") + 1).split("&");
  const params = {};
  paramArr.map((param) => {
    const [key, val] = param.split("=");
    try {
      params[key] = decodeURIComponent(val);
    } catch (e) {
      console.log(e);
    }
  });
  return params;
}

const changeQuery = (config) => {
  let distributorId = getQueryParams(location.href)["distributorId"];
  if (distributorId) {
    distributorId = decodeURIComponent(distributorId).split("#")[0];
    if (config?.method?.toUpperCase() === "GET") {
      if (config.url.indexOf("distributorId") === -1) {
        if (config.params) {
          if (!config.params.distributorId) {
            config.params.distributorId = distributorId;
          }
        } else {
          config.params = {
            distributorId: distributorId,
          };
        }
      }
    } else if (config.url.indexOf("?") > -1) {
      if (config.url.indexOf("distributorId") === -1) {
        config.url += `&distributorId=${distributorId}`;
      }
    } else {
      config.url += `?distributorId=${distributorId}`;
    }
  }
  return config;
};

// App 专用 header
const appHeaders = {};

if (platform.isApp) {
  AKJS.ready()
    .then(AKJS.info.device)
    .then((data) => {
      if (data.data.appVersion) {
        appHeaders["AKC-APP-VERSION"] = data.data.appVersion;
      }
    });
}
// 本地 App-Login-Channel 设置 （取自 启动命令后的环境标示）
const localChannelMap = {
  app: "akcApp",
  mini: "xdApplets",
  h5: "xdWxH5",
  atuan: "tuanApplets",
};
const localChannel =
  localChannelMap[process.env.REACT_APP_LOCAL_CHANNEL as keyof typeof localChannelMap];
console.log("REACT_APP_LOCAL_CHANNEL:", process.env.REACT_APP_LOCAL_CHANNEL);
console.log("app-login-channel:", localChannel);

export const Request = axios.create({
  timeout: 9000, //延迟时间
  headers: {
    "content-Type": "application/json",
    "app-login-channel": localChannel
      ? localChannel
      : platform?.isApp
        ? "akcApp"
        : platform?.isTuan
          ? "tuanApplets"
          : platform?.isMini
            ? "xdApplets"
            : "xdWxH5",
    // "app-login-channel": "xdApplets",
    "X-Hades-Env-Identity": getCookie("X-Hades-Env-Identity"),
    LS: "true",
    Xid: getQueryParams(location.href)["xid"],
  },
  withCredentials: platform.isMini ? false : true,
});

export type Response<T> = {
  code: string | number;
  success: boolean;
  message: string;
  data: T;
};

// 请求拦截
Request.interceptors.request.use((config) => {
  //拼接信息
  config.headers["auth-token"] =
    platform.isApp || platform.isMini ? stores.appStore.token : getCookie("mstoken");
  config.headers["mstoken"] =
    platform.isApp || platform.isMini ? stores.appStore.token : getCookie("mstoken");

  if (platform.isMini) {
    config.headers["auth-token"] = getQueryParams(location.href)["token"];
  }
  if (platform.isMini) {
    config.headers["mstoken"] = getQueryParams(location.href)["token"];
  }

  if (platform.isMini) {
    config.headers["auth-token"] = getQueryParams(location.href)["token"];
  }
  if (platform.isMini) {
    config.headers["mstoken"] = getQueryParams(location.href)["token"];
  }

  let shopId = getQueryParams(location.href)["shopId"];
  if (shopId && shopId !== "undefined" && shopId !== "null") {
    shopId = decodeURIComponent(shopId).split("#")[0];
    config.headers["shopId"] = shopId;

    if (!config.params?.shopBizCode) {
      if (config.params) {
        config.params.shopBizCode = shopId;
      } else {
        config.params = { shopBizCode: shopId };
      }
    }
  }
  if (config.headers["ls"]) {
    config.headers["ls"] = true;
  }

  if (!config.headers["app-request-id"]) {
    config.headers["app-request-id"] = _.random(100000000000, 999999999999999);
  }
  config.headers["app-login-channel"] = stores.appStore.channel
    ? stores.appStore.channel
    : config.headers["app-login-channel"];

  if (platform.isTuan) {
    const groupLeaderSellerId = getQueryParams(location.href)["groupLeaderSellerId"];
    const groupLeaderUserId = getQueryParams(location.href)["groupLeaderUserId"];
    const helpGroupLeaderSellerId = getQueryParams(location.href)["helpGroupLeaderSellerId"];
    const helpGroupLeaderUserId = getQueryParams(location.href)["helpGroupLeaderUserId"];
    const shopId = getQueryParams(location.href)["shopId"];
    if (groupLeaderSellerId) {
      config.headers["groupLeaderSellerId"] = groupLeaderSellerId;
    }
    if (groupLeaderUserId) {
      config.headers["groupLeaderUserId"] = groupLeaderUserId;
    }
    if (helpGroupLeaderSellerId) {
      config.headers["helpGroupLeaderSellerId"] = helpGroupLeaderSellerId;
    }
    if (helpGroupLeaderUserId) {
      config.headers["helpGroupLeaderUserId"] = helpGroupLeaderUserId;
    }
    if (shopId) {
      config.headers["shopId"] = shopId;
    }
  }
  //更换X-Hades-Env-Identity
  if (platform.isApp) {
    let envid: any = location.href.match(/[?&]env_identity=([^?#&]+)/);
    envid = envid && envid[1];
    if (envid) {
      config.headers["X-Hades-Env-Identity"] = envid || getCookie("X-Hades-Env-Identity");
    }
  } else {
    config.headers["X-Hades-Env-Identity"] = getCookie("X-Hades-Env-Identity");
  }
  if (process.env.REACT_APP_MODE === "local" && platform.isH5) {
    if (process.env.REACT_APP_MSTOKEN) {
      config.headers.mstoken = process.env.REACT_APP_MSTOKEN;
      config.headers["auth-token"] = process.env.REACT_APP_MSTOKEN;
      config.headers["xid"] = getQueryParams(location.href)["shopId"];
    }
    if (process.env.REACT_APP_HADES_ENV) {
      config.headers["X-Hades-Env-Identity"] = process.env.REACT_APP_HADES_ENV;
    }
  }
  Object.keys(appHeaders).forEach((key) => (config.headers[key] = appHeaders[key]));
  return changeQuery(config);
});

// 响应拦截

Request.interceptors.response.use((response) => {
  //判断shopId与token的
  if (
    [100006, 401, 403].indexOf(response?.data?.code) > -1 ||
    (response?.data?.code === 500 && response?.data?.message === "用户未登录")
  ) {
    checkIdentity();
  } else if (
    response?.data?.code === 100008 &&
    platform.isH5 &&
    window.location.pathname !== "/mx-shop-micro/logout/"
  ) {
    // 注销流程中
    window.location.replace(`/mx-shop-micro/logout/${window.location.search}`);
    return;
  } else if (response?.data?.code === 999998) {
    // distributorId发生变更
    reenterShop();
  } else if (response?.data?.code === 999999) {
    // shopId发生变更
    const ua = window.navigator.userAgent.toLowerCase();
    const result = ua.match(/MicroMessenger/i);
    if (result) {
      const current = `${window.location.pathname}${window.location.search}${window.location.hash}`;
      let url = "";
      const staticQuery: any = getQueryParams(location.href);
      if (getQueryParams(location.href)["distributorId"]) {
        url = `${getBaseurl()}/api/member/auth?backUrl=${window.encodeURIComponent(
          `/#/login?shopId=${staticQuery.shopId}&distributorId=${staticQuery.distributorId}&rdt=1&bU=${window.encodeURIComponent(current)}`,
        )}`;
      } else {
        url = `${getBaseurl()}/api/member/auth?backUrl=${window.encodeURIComponent(
          `/#/login?shopId=${staticQuery.shopId}&rdt=1&bU=${window.encodeURIComponent(current)}`,
        )}`;
      }

      if (staticQuery.shopId && staticQuery.shopId !== "undefined") {
        window["willRedirect"] = true;
        window.location.href = url;
      } else {
        reenterShop();
      }
    }
  }
  return response.data;
}, captureException);
