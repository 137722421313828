import { queryCouponShareFromPay, queryDetails, showCardPopup } from "@/api/afterPay";
import { getWeChatAccountInfoUrlByUserId } from "@/api/my";
import { getUrlParam, urlQueryObject } from "@/utils/url-parse";
import React, { useEffect, useState } from "react";
import FollowShopAssistant from "./components/FollowShopAssistant";
import GoodRecommendations from "./components/GoodRecommendations";
import ShareCoupon from "./components/ShareCoupon";
import styles from "./index.module.less"; // 引入 CSS Modules

const AfterPay2 = () => {
  useEffect(() => {
    const postParent = () => {
      const mchData = { action: "onIframeReady", displayStyle: "SHOW_CUSTOM_PAGE" };
      const postData = JSON.stringify(mchData);
      parent.postMessage(postData, "https://payapp.weixin.qq.com");
    };
    setTimeout(() => {
      postParent();
    }, 1000);
  }, []);

  const { userId = "userId" } = urlQueryObject();
  const sub_mch_id = getUrlParam("sub_mch_id");
  const out_trade_no = getUrlParam("out_trade_no");
  const check_code = getUrlParam("check_code");

  const [orderDetails, setOrderDetails] = useState({
    orderNo: "",
    payAmount: "",
    subOrderNo: "",
    shopId: "",
    distributorId: "",
    activitySpuIds: [],
    categoryCodeList: [],
  });
  const [followUsVisible, setFollowUsVisible] = useState(false); // 关注助手
  const [shareCouponVisible, setShareCouponVisible] = useState(false); // 分享券
  const [shareObj, setShareObj] = useState({});

  const getCouponBanner = async (shopId, orderId) => {
    const {
      data: { link, placardImgUrl },
    } = await queryCouponShareFromPay({ shopId, orderId });

    if (placardImgUrl) {
      setShareCouponVisible(true);
    } else {
      setShareCouponVisible(false);
    }

    setShareObj({
      link,
      placardImgUrl,
    });
  };
  const queryOrder = async ({ sub_mch_id, out_trade_no, check_code }) => {
    const params = {
      checkCode: check_code,
      outTradeNo: out_trade_no,
      subMchId: sub_mch_id,
    };
    try {
      const res = await queryDetails(params);
      const {
        orderNo,
        payAmount,
        subOrderNo,
        shopId,
        distributorId,
        activitySpuIds,
        categoryCodeList,
      } = res.data || {};
      setOrderDetails({
        orderNo,
        payAmount,
        subOrderNo,
        shopId,
        distributorId,
        activitySpuIds,
        categoryCodeList,
      });
      // 查询分享券
      getCouponBanner(shopId, orderNo);
    } catch (err) {
      console.error("查询订单失败", err);
    }
  };

  useEffect(() => {
    if (sub_mch_id && out_trade_no && check_code) {
      queryOrder({ sub_mch_id, out_trade_no, check_code });
    }
  }, []);

  useEffect(() => {
    // 关注助手
    const queryShowFollowUs = () => {
      getWeChatAccountInfoUrlByUserId().then((res) => {
        const { isFollow } = res.data;
        if (isFollow) {
          setFollowUsVisible(false);
        } else {
          setFollowUsVisible(true);
        }
      });
    };
    queryShowFollowUs();
  }, []);

  const handleGoHome = () => {
    const jumpOutUrl = `${process.env.REACT_APP_PAGE_URL}/mx-shop-micro/home`;
    const mchData = { action: "jumpOut", jumpOutUrl: jumpOutUrl };
    const postData = JSON.stringify(mchData);
    parent.postMessage(postData, "https://payapp.weixin.qq.com");
  };
  const handleLookOrder = () => {
    const jumpOutUrl = `${process.env.REACT_APP_PAGE_URL}/#/order/detail?orderNo=${orderDetails.subOrderNo}`;
    const mchData = { action: "jumpOut", jumpOutUrl: jumpOutUrl };
    const postData = JSON.stringify(mchData);
    parent.postMessage(postData, "https://payapp.weixin.qq.com");
  };

  const jumpOut2Product = ({ activityId, activitySpuId, selectSkuId }) => {
    const jumpOutUrl = `${process.env.REACT_APP_PAGE_URL}/#/productDetail?activityId=${activityId}&activitySpuId=${activitySpuId}&selectSkuId=${selectSkuId}`;
    const mchData = { action: "jumpOut", jumpOutUrl: jumpOutUrl };
    const postData = JSON.stringify(mchData);
    parent.postMessage(postData, "https://payapp.weixin.qq.com");
  };

  const handleJumpOut2Coupon = (link) => {
    const jumpOutUrl = link;
    const mchData = { action: "jumpOut", jumpOutUrl: jumpOutUrl };
    const postData = JSON.stringify(mchData);
    parent.postMessage(postData, "https://payapp.weixin.qq.com");
  };

  const getRecommendPageSize = (a, b) => {
    if ((a && !b) || (!a && b)) {
      return 3; // 当a和b中只有一个为true时，返回3
    } else if (!a && !b) {
      return 6; // 当a和b都为false时，返回6
    }
    return 3;
  };

  const queryParams = {
    activitySpuIds: orderDetails.activitySpuIds,
    categoryCodeList: orderDetails.categoryCodeList,
    scene: "orderPaySuc",
    pageNo: 1,
    pageSize: getRecommendPageSize(followUsVisible, shareCouponVisible),
    pageId: "H5_ProductList",
    requestId: userId + Date.now(),
    voConfig: { showType: 2 },
  };

  return (
    <div className={styles["after-pay"]}>
      <div className={styles.orderSuccess}>
        <div className={styles.successHeader}>
          <div className={styles.successText}>
            <img
              src="https://akim-oss.aikucun.com/53a5687cb26dc41f2ab4033e97e13adefd3740d6_1731896927663_48.png"
              alt="Success"
              className={styles.successIcon}
            />
            下单成功
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <button className={`${styles.btn} ${styles.btnHome}`} onClick={handleGoHome}>
            逛逛首页
          </button>
          <button className={`${styles.btn} ${styles.btnOrder}`} onClick={handleLookOrder}>
            查看订单
          </button>
        </div>
        <div className={styles.reminder}>
          温馨提示：小店不会以快递丢失/商品质量等为由，向您索取银行卡信息或手机验证码。请您时刻保持警惕，如遇问题，请您先与小店核实
        </div>

        {/* 分享券 */}
        {shareCouponVisible && (
          <ShareCoupon shareObj={shareObj} handleJumpOut2Coupon={handleJumpOut2Coupon} />
        )}
        {/* 关注助手 */}
        {followUsVisible && <FollowShopAssistant />}
        {/* 商品推荐 */}
        {/* followUsVisible, shareCouponVisible 都为ture时 不显示商品推荐 */}
        {!(shareCouponVisible && followUsVisible) && (
          <GoodRecommendations queryParams={queryParams} jumpOut2Product={jumpOut2Product} />
        )}
      </div>
    </div>
  );
};

export default AfterPay2;
