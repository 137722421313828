import { getBaseurl } from "@/utils/url-parse";

import { Request } from "./request";

// 2024-业绩提升小妙招
const queryFrontSetting = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/common/queryFrontSetting`,
    method: "get",
    params,
  });
  console.error("业绩提升小妙招", res?.data);
  return res?.data;
};

/**
 * @description 品牌伯乐奖
 * @link https://yapi.akcops.com/project/1799/interface/api/411864
 * @author 国庆
 */
const queryBrandPromotion = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/annualbonus/2024/brandBole/brandPromotion`,
    method: "get",
    params,
  });
  console.error("品牌伯乐奖", res?.data);
  return res?.data;
};

/**
 * @description 品牌伯乐奖-奖励信息
 * @link https://yapi.akcops.com/project/1799/interface/api/411873
 * @author 国庆
 */
const queryBrandPromotionStimulation = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/annualbonus/2024/brandBole/brandPromotionStimulation`,
    method: "get",
    params,
  });
  console.error("品牌伯乐奖基础信息", res?.data);
  return res?.data;
};

/**
 * @description 品牌伯乐奖-排名列表
 * @link https://yapi.akcops.com/project/1799/interface/api/411882
 * @author 国庆
 */
const queryBrandPromotionWin = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/annualbonus/2024/brandBole/brandPromotionWin`,
    method: "get",
    params,
  });
  console.error("品牌伯乐奖-排名列表", res?.data);
  return res?.data;
};

/**
 * @description 新推官、业绩冲刺-用户预估奖励查询
 * @link https://yapi.akcops.com/project/2719/interface/api/411891 
 * @author 张冰
 */
const queryAggregationStatistics = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/aggregation/statistics`,
    method: "post",
    data: params,
  });
  console.error("新推官、业绩冲刺-用户预估奖励查询", res?.data);
  return res?.data;
};

/**
 * @description 新推官、业绩冲刺-满返任务列表
 * @link https://yapi.akcops.com/project/2719/interface/api/411900
 * @author 张冰
 */
const queryTask = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/appH5/spweb/rebate/v2/aggregation/queryTask`,
    method: "post",
    data: params,
  });
  return res?.data;
};

/**
 * @description 品牌伯乐奖-根据brandActivityCode查询
 * @link https://yapi.akcops.com/project/2719/interface/api/412197
 * @author 海浪
 */
const queryByBrandActivityCode = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/annualbonus/2024/brandBole/getByBrandActivityCode`,
    method: "get",
    params,
  });
  return res?.data;
};

export {
  queryAggregationStatistics, queryBrandPromotion, queryBrandPromotionStimulation, queryBrandPromotionWin, queryByBrandActivityCode, queryFrontSetting, queryTask
};

