import { formatImgUrl } from "@/utils/crop";
import useNavigation from "@/utils/useNavigate";
import { Image, Swiper } from "antd-mobile";
import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import exposure from "../../utils/expose/exposure";
import { pxtorem } from "../../utils/pxtorem";
import styles from "./index.module.less";
interface Props {
  appStore?: any;
  propData?: any;
  pageData?: any;
  resourceRank?: any;
  trackInfo?: any;
  [key: string]: any;
}

const BannerCom: React.FC<Props> = (props: Props) => {
  const { preasableAction } = useNavigation();

  const goDetail = (item?: any, index?: any) => {
    // TODO @叮叮 传入搭建页面的 pageName
    preasableAction(
      item,
      props.pageData.name,
      "banner",
      props.resourceRank,
      index,
      props?.trackInfo,
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      const dom = document.getElementById(`${props.propData.componentCode}`);
      if (dom) {
        exposure.add({
          el: dom,
        });
      } else {
        flag = true;
      }
      if (!flag) {
        clearInterval(interval);
      }
    }, 1000);
  }, []);

  return (
    <>
      {props.propData.carouselType === "3" ? (
        <div
          style={{
            position: "relative",
            display: "flex",
            margin: `${pxtorem(props.propData?.paddingTop)} ${pxtorem(
              props.propData?.paddingRight,
            )} ${pxtorem(props.propData?.paddingBottom)} ${pxtorem(props.propData?.paddingLeft)}`,
            paddingBottom: props.bottom ? pxtorem(60) : "unset",
          }}
        >
          <div
            className={styles.banner3}
            style={{
              borderTopLeftRadius: pxtorem(props.propData?.borderTopLeftRadius),
              borderTopRightRadius: pxtorem(props.propData?.borderTopRightRadius),
              borderBottomLeftRadius: pxtorem(props.propData?.borderBottomLeftRadius),
              borderBottomRightRadius: pxtorem(props.propData?.borderBottomRightRadius),
              width: pxtorem(375 - props.propData?.paddingLeft - props.propData?.paddingRight),
              height: pxtorem(
                (375 - props.propData?.paddingLeft - props.propData?.paddingRight) * 0.8,
              ),
              overflow: "hidden",
            }}
          >
            <div className={styles.item}>
              <Swiper
                style={{
                  "--border-radius": pxtorem(props.propData?.borderTopLeftRadius),
                }}
                autoplay
                loop={true}
                autoplayInterval={2000}
                indicator={(total, current) => {
                  if (total > 1) {
                    return (
                      <div className={styles.customIndicator}>
                        {"a"
                          .repeat(total)
                          .split("")
                          .map((swiper, index) => {
                            return (
                              <div
                                key={index}
                                className={styles.banner_dot}
                                style={{
                                  background:
                                    index === current ? "#ff302d" : "rgba(204, 204, 204, 1)",
                                }}
                              ></div>
                            );
                          })}
                      </div>
                    );
                  }
                }}
              >
                {props.propData?.carouselConfigDetails?.map((item, index) => (
                  <Swiper.Item key={index} style={{ width: "100%", height: "100%" }}>
                    <div
                      id={`${props.propData?.componentCode}`}
                      data-param={JSON.stringify({
                        type: "banner",
                        content: "banner",
                        picture: true,
                        hasSpecially: false,
                        resourceRank:
                          props.navResourceRank !== null && props.navResourceRank !== undefined
                            ? props.navResourceRank
                            : props.resourceRank,
                        relativeRank: index,
                        ...props.trackInfo,
                      })}
                      data-eventid={props.propData.componentCode}
                      style={{ textAlign: "center", width: "100%", height: "100%" }}
                    >
                      <Image
                        style={{
                          width: "100%",
                          height: "100%",
                          borderTopLeftRadius: pxtorem(props.propData?.borderTopLeftRadius),
                          borderTopRightRadius: pxtorem(props.propData?.borderTopRightRadius),
                          borderBottomLeftRadius: pxtorem(props.propData?.borderBottomLeftRadius),
                          borderBottomRightRadius: pxtorem(props.propData?.borderBottomRightRadius),
                          margin: "auto",
                        }}
                        fit="cover"
                        src={formatImgUrl(item.img, 351, 130, "mfit", false)}
                        onClick={() => goDetail(item, index)}
                      />
                    </div>
                  </Swiper.Item>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            background: `url(${props.propData.backgroundImgUrl}) no-repeat `,
            backgroundSize: "cover",
            margin: `${pxtorem(props.propData?.paddingTop)} 0 ${pxtorem(
              props.propData?.paddingBottom,
            )} 0`,
            position: "relative",
            minHeight: pxtorem(144),
          }}
        >
          {props.propData.backgroundImgUrl && <Image src={props.propData.backgroundImgUrl}></Image>}
          <div
            className={styles.banner}
            style={{
              borderTopLeftRadius: pxtorem(props.propData?.borderTopLeftRadius),
              borderTopRightRadius: pxtorem(props.propData?.borderTopRightRadius),
              borderBottomLeftRadius: pxtorem(props.propData?.borderBottomLeftRadius),
              borderBottomRightRadius: pxtorem(props.propData?.borderBottomRightRadius),
              position: "absolute",
              bottom: props.propData.backgroundImgUrl ? pxtorem(12) : "unset",
              width: "100%",
            }}
          >
            <div className={styles.item}>
              <Swiper
                style={{
                  "--border-radius": pxtorem(props.propData?.borderTopLeftRadius),
                  "--track-padding": `0 0 ${pxtorem(4)}`,
                }}
                autoplay
                loop={true}
                autoplayInterval={2000}
                indicator={(total, current) => {
                  if (total > 1) {
                    return (
                      <div className={styles.customIndicator}>
                        {"a"
                          .repeat(total)
                          .split("")
                          .map((swiper, index) => {
                            return (
                              <div
                                key={index}
                                className={styles.banner_dot}
                                style={{
                                  background:
                                    index === current ? "#ff302d" : "rgba(204, 204, 204, 1)",
                                }}
                              ></div>
                            );
                          })}
                      </div>
                    );
                  }
                }}
              >
                {props.propData?.carouselConfigDetails?.map((item, index) => {
                  return (
                    <Swiper.Item key={index}>
                      <div
                        id={`${props.propData.componentCode}`}
                        data-param={JSON.stringify({
                          type: "banner",
                          content: "banner",
                          picture: true,
                          hasSpecially: false,
                          resourceRank: props.navResourceRank
                            ? props.navResourceRank
                            : props.resourceRank,
                          relativeRank: index,
                          ...props.trackInfo,
                        })}
                        data-eventid={props.propData.componentCode}
                      >
                        <Image
                          style={{
                            width: pxtorem(351),
                            height: pxtorem(130),
                            borderTopLeftRadius: pxtorem(props.propData?.borderTopLeftRadius),
                            borderTopRightRadius: pxtorem(props.propData?.borderTopRightRadius),
                            borderBottomLeftRadius: pxtorem(props.propData?.borderBottomLeftRadius),
                            borderBottomRightRadius: pxtorem(
                              props.propData?.borderBottomRightRadius,
                            ),
                            margin: "auto",
                          }}
                          fit="cover"
                          src={formatImgUrl(item.img, 351, 130, "mfit", false)}
                          onClick={() => goDetail(item, index)}
                        />
                      </div>
                    </Swiper.Item>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default inject("appStore")(observer(BannerCom));
