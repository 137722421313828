import { ImageViewer } from "antd-mobile";
import React, { useEffect, useState } from "react";
import styles from "./ADMZ.module.less";

interface Props {
  info: any;
}
const ADMZ: React.FC<Props> = ({ info }) => {
  // 目标枚举
  const thresholdIndicatorTypeEnum = {
    GMV: { desc: "销售额", unit: "元" },
    SALE_QUANTITY: { desc: "销量", unit: "件" },
    COMMISSION: { desc: "基础省赚", unit: "元" },
  };

  const [currentGmv, setCurrentGmv] = useState(0); // 当前累计
  const [showImageViewer, setShowImageViewer] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [isRankMZ, setIsRankMZ] = useState(false);
  const [ruleDetails, setRuleDetails] = useState<any>([]); // 进度数组
  const [isMultistage, setIsMultistage] = useState(false); // 进度数组是否超过3段(要展示...省略号)

  const gmvDom = () => {
    if (!info) {
      return;
    }
    if (isRankMZ) {
      // 限量
      if (!info.isQualified) {
        // 没入围
        return (
          <p>
            当前累计
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].desc}
            <span>
              {info.actSellBatePartakeInfo.currentGmv}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
            </span>
            ， 仅差
            <span>
              {info?.actSellBatePartakeInfo?.unChosenAmount}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
            </span>
            入围 ，继续努力呀~
          </p>
        );
      } else if (info?.actSellBatePartakeInfo?.currentRank === 1) {
        return (
          <p>
            当前累计
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].desc}
            <span>
              {info.actSellBatePartakeInfo.currentGmv}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
            </span>
            ，<span>排名{info.actSellBatePartakeInfo.currentRank}名</span>
            距下一名仅差
            <span>
              {info?.actSellBatePartakeInfo?.unChosenAmount}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
            </span>
            ，继续努力呀~
          </p>
        );
      } else {
        return (
          <p>
            当前累计
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].desc}
            <span>
              {info.actSellBatePartakeInfo.currentGmv}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
            </span>
            ，<span>排名{info.actSellBatePartakeInfo.currentRank}名</span>
            距上一名仅差
            <span>
              {info?.actSellBatePartakeInfo?.unChosenAmount}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
            </span>
            ，继续努力呀~
          </p>
        );
      }
    } else {
      // 不限量
      if (info?.actSellBatePartakeInfo?.nextRewardAmount) {
        return (
          <p>
            当前累计
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].desc}
            <span>
              {currentGmv}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
            </span>
            ，
            <span>
              仅差{info?.actSellBatePartakeInfo?.nextRewardAmount}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
            </span>
            就可以获得
            <span>
              {info?.actSellBatePartakeInfo?.nextRuleRewardGiftName}*
              {info?.actSellBatePartakeInfo?.nextRuleRewardAmount}
            </span>
            ，继续努力呀~
          </p>
        );
      } else {
        return (
          <p>
            当前累计
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].desc}
            <span>
              {currentGmv}
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
            </span>
            ，预计可得
            <span>
              {info?.actSellBatePartakeInfo?.giftName}*
              {info?.actSellBatePartakeInfo?.expectedAwardAmount}
            </span>
            ，继续努力呀~
          </p>
        );
      }
    }
  };

  const matchTypeOfDataCaliberDom = () => {
    let dom = <></>;
    switch (info?.actSellBatePartakeInfo?.thresholdIndicatorType) {
      case "GMV": // 销售额
        dom = (
          <li className={styles.data__item}>
            <span className={styles.item__value}>{info?.actSellBatePartakeInfo?.saleAmount}</span>
            <span className={styles.item__key}>累计销售额(元)</span>
          </li>
        );
        break;
      case "SALE_QUANTITY": // 销量
        dom = (
          <li className={styles.data__item}>
            <span className={styles.item__value}>
              {info?.actSellBatePartakeInfo?.totalSaleQuantity}
            </span>
            <span className={styles.item__key}>累计销量(件)</span>
          </li>
        );
        break;
      case "COMMISSION": // 基础省赚
        dom = (
          <li className={styles.data__item}>
            <span className={styles.item__value}>
              {info?.actSellBatePartakeInfo?.totalCommission}
            </span>
            <span className={styles.item__key}>累计基础省赚(元)</span>
          </li>
        );
        break;
    }
    return dom;
  };

  const totalDom = () => {
    if (isRankMZ) {
      return (
        <ul className={styles.achievement__rank}>
          <li className={`${styles.rank__item} ${styles.rank__title}`}>
            <span className={styles.item__0}>排名</span>
            <span className={styles.item__1}>用户信息</span>
            <span className={styles.item__2}>
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].desc}(
              {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit})
            </span>
          </li>
          {info?.actSellBatePartakeInfo?.currentRankDetails[0].rank !== 1 && (
            <li className={`${styles.rank__item}`}>
              <span className={styles.item__0}>...</span>
              <span className={styles.item__1}>...</span>
              <span className={styles.item__2}>...</span>
            </li>
          )}
          {info?.actSellBatePartakeInfo?.currentRankDetails.map((item, index) => {
            return (
              <li
                key={index}
                className={`${styles.rank__item} ${
                  item.isCurrentUser && styles.rank__item_active
                } ${info.isQualified && item.rank === 1 && styles.rank__1} ${
                  info.isQualified && item.rank === 2 && styles.rank__2
                } ${info.isQualified && item.rank === 3 && styles.rank__3}`}
              >
                <span className={styles.item__0}>{info.isQualified ? item.rank : "未入围"}</span>
                <span className={`${styles.item__1} ${styles["text-ellipsis1"]}`}>
                  {item.isCurrentUser ? "我" : item.nickname || item.userCode}
                </span>
                <span className={styles.item__2}>{item.saleAmount || 0}</span>
              </li>
            );
          })}
        </ul>
      );
    } else {
      if (info?.actSellBatePartakeInfo?.saleAmount > 0) {
        return (
          <>
            <ul className={styles.achievement__data}>
              {matchTypeOfDataCaliberDom()}
              <li className={styles.data__item}>
                <span className={styles.item__value}>
                  {info?.actSellBatePartakeInfo?.totalOrderNum}
                </span>
                <span className={styles.item__key}>订单总数</span>
              </li>
              <li className={styles.data__item}>
                <span className={styles.item__value}>
                  {info?.actSellBatePartakeInfo?.afterSaleOrderNum}
                </span>
                <span className={styles.item__key}>售后订单</span>
              </li>
            </ul>
            <div className={styles.achievement__tips}>*销售数据每隔1小时刷新1次</div>
          </>
        );
      }
    }
  };

  const getPercent = (item) => {
    if(item.isInfinite) { return 0; }
    if (currentGmv > item.strThreshold) {
      return currentGmv < item.endThreshold
        ? ((currentGmv - item.strThreshold) / (item.endThreshold - item.strThreshold)) * 100
        : 100;
    } else {
      return 0;
    }
  };

  const handleRuleDetails = () => {
    const _ruleDetails = info?.actSellRebateRuleInfos?.ruleDetails || [];
    const maxLength = 3;
    setIsMultistage(_ruleDetails.length > maxLength);
    if(_ruleDetails.length <= maxLength) {
      setRuleDetails(_ruleDetails);
    } else {
      /**
       * 1. 初始展示前两个阶梯+最后一个阶梯值;
       * 2. 当业绩超过两个后，销售业绩每增加一个阶梯，前面就少展示一个阶梯;
       * 3. 当业绩到最后3个阶梯间，固定展示最后3个阶梯;
       */
      let currentIndex = _ruleDetails.findIndex(item => {
        return item.endThreshold > currentGmv;
      })
      let isRewardSuperimpositionType = info?.actSellRebateRuleInfos?.rewardSuperimpositionType === 'SUPERIMPOSITION'; // 是否可叠加
      let isExistCeilingTimes = info?.actSellBatePartakeInfo?.isExistCeilingTimes; // 是否存在封顶次数
      // let ceilingTimes = info?.actSellBatePartakeInfo?.ceilingTimes; // 次数
      let isInfinite =  isRewardSuperimpositionType && !isExistCeilingTimes; // 是否不封顶叠加
      let newList:any[] = [];
      if (currentIndex >= _ruleDetails.length - 3) {
        newList = isInfinite ? [..._ruleDetails.slice(-2), {
          isInfinite: true,
          giftStr: '上不封顶',
          endThreshold: '多卖多得'
        }] : _ruleDetails.slice(-3);
      } else if(currentIndex > 1) {
        newList = [_ruleDetails[currentIndex],_ruleDetails[currentIndex+1], _ruleDetails[_ruleDetails.length -1]];
      } else {
        newList = [_ruleDetails[0],_ruleDetails[1],_ruleDetails[_ruleDetails.length -1]];
      }
      if(isInfinite) {
        newList[newList.length - 1] = {
          isInfinite: true,
          giftStr: '上不封顶',
          endThreshold: '多卖多得'
        }
        setRuleDetails(newList);
      } else {
        setRuleDetails(newList);
      }
    }
  }

  useEffect(() => {
    handleRuleDetails();
  }, [currentGmv])

  useEffect(() => {
    if (!info) {
      return;
    }
    setCurrentGmv(info?.actSellBatePartakeInfo?.currentGmv || 0);
    setIsRankMZ(info?.actSellBatePartakeInfo?.isLimitAwardRanking);
  }, [info]);

  return (
    <>
      {!currentGmv && (
        <div className={styles.reward}>
          <h3 className={styles.reward__title}>活动奖品</h3>
          {ruleDetails?.length > 0 && (
            <ul
              className={`${styles.reward__list} ${
                ruleDetails.length === 1 && styles.reward__list_1
              }
              ${ruleDetails.length === 2 && styles.reward__list_2}
                            ${
                              ruleDetails.length > 3 &&
                              styles.reward__list_more
                            }
            }`}
            >
              {ruleDetails.map((item, index) => {
                return (
                  <li key={index} className={styles.list_item}>
                    <img className={styles.item_picture} src={item.giftImageUrl} />
                    <span className={styles.item_desc}>
                      <span className={styles.desc__left}>{item.giftName}</span>
                      <span className={styles.desc__right}>*{item.rewardAmount}</span>
                    </span>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}

      {currentGmv > 0 && (
        <div className={styles.achievement}>
          <h3 className={styles.achievement__title}>我的业绩</h3>
          {info?.actSellBatePartakeInfo?.awardTopRankingNumber > 0 && (
            <div className={styles.achievement__subtitle}>
              仅限前{info.actSellBatePartakeInfo.awardTopRankingNumber}名可获得奖励
            </div>
          )}
          <div className={styles.achievement__progress}>
            <div className={styles.progress__list}>
              <div className={`${styles.list__title}`}>
                <div
                  className={`${styles.item__icon} ${currentGmv && styles["item__icon_active"]}`}
                ></div>
                <div className={styles.item__con}>
                  <p className={styles.con__0}>奖品</p>
                  <p className={styles.con__1}>
                    {
                      thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]
                        .desc
                    }
                    (
                    {
                      thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]
                        .unit
                    }
                    )
                  </p>
                </div>
              </div>
              {ruleDetails?.length && (
                <ul className={styles.list__con}>
                  {ruleDetails?.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={`${styles.list__item} ${
                          ruleDetails?.length === 1 &&
                          styles.list__lenght_1
                        } ${
                          ruleDetails?.length === 2 &&
                          styles.list__lenght_2
                        } ${
                          isMultistage && index === 2  && styles.list__multistage
                        }`}
                      >
                        <div className={styles.item__line}>
                          <div
                            className={styles.line__current}
                            style={{
                              width: `${getPercent(item)}%`,
                            }}
                          ></div>
                        </div>
                        <div
                          className={`${styles.item__icon} ${
                            currentGmv >= item.endThreshold && styles["item__icon_active"]
                          }`}
                        ></div>
                        <div className={styles.item__con}>
                          {
                            item.isInfinite ? <p className={styles.con__str}>{item.giftStr}</p> : (
                              <img
                                className={styles.con__picture}
                                src={item.giftImageUrl}
                                onClick={() => {
                                  setImageSrc(item.giftImageUrl);
                                  setShowImageViewer(true);
                                }}
                            />
                            )
                          }
                          <p className={`${styles.con__sales} ${item.isInfinite && styles.ff585e}`}>{item.endThreshold}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
          {info?.actSellBatePartakeInfo?.unChosenAmount >= 0 && (
            <div className={styles.achievement__gmv}>{gmvDom()}</div>
          )}
          <div>{totalDom()}</div>
        </div>
      )}

      <ImageViewer
        classNames={{
          mask: "customize-mask",
          body: "customize-body",
        }}
        image={imageSrc}
        visible={showImageViewer}
        onClose={() => {
          setImageSrc("");
          setShowImageViewer(false);
        }}
      />
    </>
  );
};

export default ADMZ;
