import { apiGetWaitTotalPoints } from "@/api/home";
import { getPoint } from "@/api/my";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import { Swiper } from "antd-mobile";
import React, { useEffect } from "react";
import { useActivate } from "react-activation";
import { btn_click } from "../../track";

import styles from "./index.module.less";

const ICON_COIN =
  "https://akim-oss.aikucun.com/mshop/3478fe69c7dcebc14f62b322a44059dedf000466_1679731292625_52.png";
const ICON_COIN_ARROW =
  "https://akim-oss.aikucun.com/mshop/39a531d92bbe1cfd6268cd99d999880c84640b1d_1679731292612_50.png";
const MemberCenter: React.FC = () => {
  const [memberInfo, setMemberInfo] = React.useState<any>({});
  const [waitTotalAmount, setWaitTotalAmount] = React.useState<number>(0);
  const [carouselProducts, setCarouselProducts] = React.useState<any[]>([]);
  const { navigate2H5 } = useNavigation();
  const reqPoint = async () => {
    try {
      const { data }: any = await getPoint();
      setMemberInfo(data);
      if (data.carouselProducts) {
        console.log("data.carouselProducts", data.carouselProducts);
        setCarouselProducts(data.carouselProducts);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getWaitTotalPoints = async () => {
    const data = await apiGetWaitTotalPoints();
    setWaitTotalAmount(data?.waitTotalAmount || 0);
  };
  const goPoint = () => {
    const options: IOptions = {
      path: "/#/membercenter",
      is_micro_app: true,
    };
    navigate2H5(options);
    btn_click("我的-头部功能区", "去积分中心");
  };
  const goto = (item) => {
    if (item.type === 1) {
      const options: IOptions = {
        path: "/#/productDetail",
        query: `productId=${item.productNo}&activityNo=${item.activityId}`,
        is_micro_app: true,
      };
      navigate2H5(options);
    } else {
      const options: IOptions = {
        path: "/#/membercenter",
        is_micro_app: true,
      };
      navigate2H5(options);
      btn_click("我的-头部功能区", "去积分中心");
    }
  };
  useEffect(() => {
    reqPoint();
    getWaitTotalPoints();
  }, []);
  useActivate(() => {
    getWaitTotalPoints();
  });
  return (
    <div className={styles["member_center_integral_wrap"]}>
      <div
        className={`${styles["member_center_integral"]} ${
          styles[`member_center_integral_${memberInfo.level}`]
        } ${carouselProducts.length === 0 ? styles["no_product"] : styles[""]}`}
      >
        <div className={styles["header"]}>
          <div className={styles["left"]} onClick={goPoint}>
            可用积分：<span className={styles["num"]}>{memberInfo.freeBalance}</span>
            <img className={styles["icon_coin"]} src={ICON_COIN} alt="" />
            <img className={styles["icon_coin_arrow"]} src={ICON_COIN_ARROW} alt="" />
            {waitTotalAmount > 0 && (
              <span className={styles["receive"]}>{waitTotalAmount}分待领取</span>
            )}
          </div>
          <div className={`${styles["right"]} ${styles[`vip_rank_${memberInfo.level}`]}`}></div>
        </div>
        {carouselProducts.length > 0 && (
          <Swiper
            autoplay={true}
            autoplayInterval={3000}
            loop={true}
            indicator={() => null}
            style={{ height: "63px", borderRadius: "9px", marginTop: "3px" }}
          >
            {carouselProducts.map((item, index) => (
              <Swiper.Item key={index}>
                <div
                  className={styles["content"]}
                  onClick={() => {
                    goto(item);
                  }}
                >
                  <div className={styles["left"]}>
                    <div
                      className={styles["product_img"]}
                      style={{
                        backgroundImage: `url(${item.pictureUrl})`,
                      }}
                    >
                      <div className={styles["score"]}>{item.amount}分</div>
                    </div>
                    <div className={styles["center"]}>
                      <div className={`${styles["top"]} ${styles["text-ellipsis"]}`}>
                        {item.productTitle}
                      </div>
                      <div className={`${styles["bottom"]} ${styles["text-ellipsis"]}`}>
                        {item.productDesc}
                      </div>
                    </div>
                  </div>
                  <div className={styles["right"]}>{item.type === 1 ? "去兑换" : "赚积分"}</div>
                </div>
              </Swiper.Item>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};
export default MemberCenter;
