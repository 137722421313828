import { captureMessage } from "@/utils/sentry";
import React, { useEffect, useState } from "react";

const AfterPay = () => {
  useEffect(() => {
    const postParent = () => {
      captureMessage("AfterPay-111", "info");
      const mchData = { action: "onIframeReady", displayStyle: "SHOW_CUSTOM_PAGE" };
      captureMessage("AfterPay-222", "info");
      const postData = JSON.stringify(mchData);
      captureMessage("AfterPay-333", "info");
      parent.postMessage(postData, "https://payapp.weixin.qq.com");
      captureMessage("AfterPay-444", "info");
    };
    setTimeout(() => {
      postParent();
    }, 1000);
  }, []);

  return <div>小票页面</div>;
};

export default AfterPay;
