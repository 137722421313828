import { getBaseurl } from "@/utils/url-parse";
import { Request } from "./request";

// 查询
export function queryDetails(params) {
  return Request({
    url: `${getBaseurl()}/api/payment/yee/payQuery`,
    headers: {
      "X-Hades-Env-Identity": "group202404121100191360",
    },
    method: "post",
    data: params,
  });
}

// 支付分享券 20200227 C端分享券
// couponId
// shopId
export function queryCouponShareFromPay(params) {
  const url = `${getBaseurl()}/api/member/coupon/shareCoupons/${params.shopId}`;
  return Request({
    url: url,
    method: "get",
    params,
  });
}

export function selectCouponInfoPaySuccess(params) {
  return Request({
    url: `${getBaseurl()}/api/spweb/order/after/share/coupon/iteration/selectCouponInfo`,
    method: "get",
    params,
  });
}

export function selectCouponInfo(params) {
  return Request({
    url: `${getBaseurl()}/api/spweb/order/after/share/coupon/selectCouponInfo`,
    method: "get",
    params,
  });
}

export function showCardPopup(params) {
  return Request({
    url: `${getBaseurl()}/api/member/xdwxh5/vip/card/popup`,
    method: "get",
    params,
  });
}
