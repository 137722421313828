import { queryProductList } from "@/api/product";
import { hostEnv } from "@/utils/platform";
import { ProductModel } from "@akc/biz-api";
import constate from "constate";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

const useProduct = () => {
  const [search] = useSearchParams();

  const [productList, setProductList] = useState<Array<ProductModel>>([]);
  const currentPageNum = useRef<number>(1);
  const currentRequestId = useRef(0);
  const [hasNext, setHasNext] = useState(true);
  const isLoading = useRef(false);
  const [brandIds, setBrandIds] = useState<any>([]);
  const [merchantCodeList, setMerchantCodeList] = useState<any>([]);

  const updateProductList = async (requestId: number) => {
    if(!(brandIds?.length > 0 && merchantCodeList?.length > 0)) { return }
    isLoading.current = true;
    const res = await queryProductList(`${hostEnv}_Search`, {
      scene: "ANNUALBONUS_BRAND_BOLE",
      pageNum: currentPageNum.current,
      pageSize: 10,
      brandIds,
      merchantCodeList
    });
    if (requestId !== currentRequestId.current) {
      return;
    }
    setHasNext(res.hasNext);
    setProductList((productList) => {
      return [
        ...productList,
        ...(res.result?.map((item, index) => ({
          ...item,
          rank: productList.length + index,
        })) ?? []),
      ];
    });
    currentPageNum.current = res.pageIndex + 1;
    isLoading.current = false;
  };

  const loadMore = async () => {
    if (typeof currentPageNum.current === "undefined" || isLoading.current) {
      return;
    }
    currentRequestId.current = Math.floor(Math.random() * 10000000000);
    await updateProductList(currentRequestId.current);
  };

  useEffect(() => {
    if(!(brandIds?.length > 0 && merchantCodeList?.length > 0)) { return }
    loadMore();
  }, [brandIds, merchantCodeList]);

  useEffect(() => {
    loadMore();
  }, []);

  return {
    productList,
    hasNext,
    loadMore,
    setBrandIds,
    setMerchantCodeList
  };
};

export default useProduct;
