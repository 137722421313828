import { AppOrderDirectResponse } from "@/api/order/model";
import { appOrderDirect, createOrder } from "@/api/order/order";
import { rewardProductDetial } from "@/api/rewardCenter";
import { formatImgUrl } from "@/utils/crop";
import { platform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import AKJS from "@akc/akjs";
import { Response, RewardProductModel, SkuInfoMappingModel } from "@akc/biz-api";
import { ProductSKUPannelComp } from "@akc/mx-taro-h5-components";
import { Button, Dialog, Image, Modal, SafeArea, Toast } from "antd-mobile";
import React from "react";
import useAddress from "../hook/useAddress";
import styles from "./ProductReward.module.less";

const containerId = "daily-reward-product-reward-popup";

const toast = (message: string, duration?: number) => {
  Toast.show({
    content: message,
    duration,
    getContainer: () => document.getElementById(containerId) as HTMLElement,
  });
};

const ProductReward: React.FC<{
  /** 商品活动 spu code */
  activitySpuId?: string;
  /** 抽奖活动 id */
  rewardActivityId?: string;
  /** 中奖记录 id */
  rewardRecordId?: string;
  /** 提交成功回调 */
  onSubmitCallback?: () => void;
}> = (props) => {
  const { addressId, addressText, openAddrPage } = useAddress();
  const { isMini, isApp, isSaas } = platform;
  const { navigate2OrderDetail } = useNavigation();

  const [product, setProduct] = React.useState<RewardProductModel>();
  const [selectedSKUInfo, setSelectedSKUInfo] = React.useState<SkuInfoMappingModel>();
  const [commitBtnLoading, setCommitBtnLoading] = React.useState(false);
  const [commitBtnText, setCommitBtnText] = React.useState("提交中");

  React.useEffect(() => {
    const func = async () => {
      if (!props.activitySpuId) {
        console.error("activitySpuId 无值");
        return;
      }
      const productDetail = await rewardProductDetial({
        addressId: addressId,
        activitySpuId: props.activitySpuId,
      });
      setProduct(productDetail);
      const selInfo =
        productDetail?.salePropertySkuIdInfoVOList?.find(
          (e) => e.skuId === productDetail?.selectSkuId,
        ) ?? productDetail?.salePropertySkuIdInfoVOList?.[0];
      setSelectedSKUInfo(selInfo);
      if (!(productDetail.allowDeliver ?? true)) {
        // 不能发货
        toast("很抱歉，你所选地址超出赠品商家配送范围，请理解并更换地址。", 3000);
        return;
      }
    };
    func();
  }, [props.activitySpuId, addressId]);

  // 提交订单
  const commitOrder = async () => {
    if (!product || !selectedSKUInfo) {
      console.error("product 或 skuInfo 为空");
      return;
    }
    if (!addressId) {
      toast("请新增或选择收货地址");
      return;
    }
    if (!(product.allowDeliver ?? true)) {
      // 不能发货
      toast("很抱歉，你所选地址超出赠品商家配送范围，请理解并更换地址。", 3000);
      return;
    }

    Dialog.confirm({
      getContainer: () =>
        document.getElementById("dailyRewardContainer") ||
        (document.getElementById("page") as HTMLElement),
      title: <span className={styles.alertTitle}>请确认领奖信息</span>,
      style: { zIndex: 100000 },
      content: (
        <span className={styles.alertContent}>
          请核对您的收货地址与奖品规格信息是否准确无误，确认后即可立即领取，领取后信息不可修改。
        </span>
      ),
      confirmText: <span className={styles.alertConfirmText}>立即领取</span>,
      onConfirm: async () => {
        try {
          let orderData: Response<AppOrderDirectResponse> | undefined;
          const promoActivityId = props.rewardActivityId;
          const voucherNo = props.rewardRecordId;
          setCommitBtnText("提交中");
          setCommitBtnLoading(true);
          if (isApp) {
            // App 走直购接口
            await AKJS.ready();
            const userInfo = (await AKJS.info.account()).data;
            orderData = await appOrderDirect({
              liveId: product.activityId,
              skuIds: [selectedSKUInfo?.skuId ?? ""],
              spuId: product.activitySpuId,
              userId: userInfo?.userID,
              subUserId: userInfo?.subuserid,
              addrId: addressId,
              quantity: 1,
              prizeSource: 3,
              promoActivityId,
              voucherNo,
            });
          } else {
            orderData = await createOrder({
              addressId: addressId,
              products: [
                {
                  liveId: product.activityId,
                  quantity: 1,
                  skuId: selectedSKUInfo?.skuId,
                  spuId: product.activitySpuId,
                },
              ],
              terminal: isMini ? "WECHAT" : "H5",
              prizeSource: isMini ? (isSaas ? 2 : 1) : 1,
              promoActivityId,
              voucherNo,
            });
          }
          if (!(orderData?.success ?? false)) {
            const message = orderData.message ?? "提交订单失败";
            toast(message);
            setCommitBtnLoading(false);
            return;
          }
          // 成功
          toast("提交成功，可在我的奖励列表查看订单", 3000);
          setCommitBtnText("处理中");
          setTimeout(() => {
            // 服务有延迟，加上 toast，估推迟 3s
            setCommitBtnLoading(false);
            props.onSubmitCallback?.();
            // 进入订单详情
            const orderNo = orderData?.data?.orderNos?.[0];
            if (orderNo) {
              navigate2OrderDetail({ orderNo });
            }
          }, 3000);
        } catch (e: any) {
          console.error("实物奖提交订单失败", e.toString());
        }
      },
      cancelText: <span className={styles.alertCancelText}>再看看</span>,
    });
  };

  if (!product) {
    return <></>;
  }

  // 有库存 && 可发货时按钮可用
  const commitBtnEnable =
    (selectedSKUInfo?.sellOut || false) === false && (product.allowDeliver ?? true) === true;

  return (
    <div className={styles.wrap} id={containerId}>
      <div className={styles.header}>
        <Image
          src="https://akim-oss.aikucun.com/2d0c8af807ef45ac17cafb2973d866ba8f38caa9_1724401596074_5.png"
          className={styles.addrIcon}
        />
        {!(product.allowDeliver ?? true) && (
          <div className={styles.notDeliver}>该地址不支持发货</div>
        )}
        <div
          className={[
            styles.addrMain,
            !(product.allowDeliver ?? true) ? styles.addrMainNotDeliver : undefined,
          ].join(" ")}
        >
          {addressText}
        </div>
        <div className={styles.addrBtn} onClick={openAddrPage}>
          {addressId ? "修改地址" : "新增地址"}
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.sku}>
          <span className={styles.skuTips}>
            恭喜你获得
            {(product?.tagPrice ?? 0) > 0 && "价值 "}
            {(product?.tagPrice ?? 0) > 0 && (
              <span className={styles.skuTipsTagPrice}>{product?.tagPrice}元</span>
            )}
            {(product?.tagPrice ?? 0) > 0 && " 的"}
          </span>
          <Image
            className={styles.skuImg}
            fit="cover"
            src={formatImgUrl(
              product?.salePropertyFirstList?.find(
                (i) => i.value === selectedSKUInfo?.firstPropertyValue,
              )?.picList?.[0] ?? product?.salePropertyFirstList?.[0]?.picList?.[0],
              82,
              82,
              "mfit",
              false,
            )}
          />
          <span className={styles.skuTitle}>{product?.skuBaseInfoVO?.title}</span>
        </div>
        <ProductSKUPannelComp
          className={styles.propList}
          rewardProduct={product}
          packUpSellOut={true}
          sellOutText="抢光"
          onPropertyAction={({ skuInfo }) => setSelectedSKUInfo(skuInfo)}
        />
      </div>
      <div className={styles.commitContainer}>
        <Button
          className={styles.commitBtn}
          disabled={!commitBtnEnable}
          loading={commitBtnLoading}
          loadingText={commitBtnText}
          onClick={commitOrder}
        >
          立即 0元 领取实物奖
        </Button>
        {/* <SafeArea position="bottom" /> */}
      </div>
    </div>
  );
};

export default ProductReward;
