import { queryDetailServ, saveOrUpdateAddressServ } from "@/api/appH5";
import loadingPng from "@/base64";
import PosterSwipe, { CustomEditData } from "@/components/poster-swiper";
import {
  onShareAppMessageByB2R,
  shareB2RPosterAction,
  sharePosterParams4H5B2R,
} from "@/utils/actions";
import { countDown } from "@/utils/date-format";
import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import { Image, Toast } from "antd-mobile";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import ADMF from "./components/ADMF";
import ADMZ from "./components/ADMZ";
import Follow from "./components/Follow/Follow";
import PK from "./components/PK";
import QueryWinning from "./components/QueryWinning";
import ResultList from "./components/ResultList";
import ToExChangeTip from "./components/ToExchangeTip/ToExchangeTip";
import styles from "./detail.module.less";

const rebateCenter: React.FC = () => {
  document.title = "活动详情";

  const { navigate2H5 } = useNavigation();

  const track = Track();
  // 埋点上下文数据设置
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setTrackData } = useContext(TrackDataContext);

  const [search] = useSearchParams();
  const actId = search.get("actId") || "";
  const id = search.get("id") || "";
  const addressId = search.get("addressId") || "";
  const awardQuantity = search.get("awardQuantity") || 1;
  const tabIndex = search.get("tabIndex") ? Number(search.get("tabIndex")) : 0;

  const tabList = [
    { label: "基础信息", value: 0 },
    { label: "中奖查询", value: 1 },
  ];
  const [tabActiveIndex, setTabActiveIndex] = useState(0);
  const [info, setInfo] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [showAllRuleDesc, setShowAllRuleDesc] = useState(false);
  const [timeId, setTimeId] = useState<any>("");
  const [countDownObj, setCountDownObj] = useState<any>({});
  const PageScrollRef = useRef<HTMLDivElement>(null);
  // 转发海报相关
  const [showPoster, setShowPoster] = useState(false);
  // 转发卡片（修改文案）需要的参数
  const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const [sharePosterData, setSharePosterData] = useState<any>({});
  const [cardList, setCardList] = useState<any>([]);

  //切换tab
  const changeTab = (index: number) => {
    setTabActiveIndex(index);
    PageScrollRef?.current?.scrollTo(0, 0);
  };

  const countDownDom = () => {
    if (countDownObj.day) {
      return (
        <>
          <span className={styles["block"]}>{countDownObj.day}</span>天
          <span className={styles["block"]}>{countDownObj.hou}</span>时
        </>
      );
    } else if (countDownObj.hou >= 1 && countDownObj.hou <= 24) {
      return (
        <>
          <span className={styles["block"]}>{countDownObj.hou}</span>时
          <span className={styles["block"]}>{countDownObj.min}</span>分
        </>
      );
    } else if (countDownObj.hou <= 1) {
      return (
        <>
          <span className={styles["block"]}>{countDownObj.min}</span>:
          <span className={styles["block"]}>{countDownObj.sec}</span>
        </>
      );
    }
  };

  const onAddAddress = async () => {
    const res = await saveOrUpdateAddressServ({
      marketingId: actId,
      addressId: id,
      baseAddressId: addressId,
      awardQuantity: Number(awardQuantity),
    });
    const { code, success, message = "" } = res || {};
    if (code === 0 && success) {
      Toast.show({
        content: "添加地址成功",
        maskClickable: false,
        maskStyle: { zIndex: 9999 },
      });
    } else {
      Toast.show({
        content: message,
        maskClickable: false,
        maskStyle: { zIndex: 9999 },
      });
    }
  };

  const onShare = async (e) => {
    e.stopPropagation();
    track.track("resource_click", {
      page_name: info.pageName,
      previous_page_name: search.get("page_name"),
      domain: "激励活动",
      // tab_name: "",
      resource_type: info?.relevanceTypeStr,
      resource_name: `${info?.relevanceTypeStr}名称`,
      resource_content: info?.relevanceTypeStr,
      resource_rank: 0,
      resource_id: search.get("actId"),
      // merchant_shop_code: "",
      // activity_id: "",
      // activity_spu_code: "",
      btn_name: `分享${info?.relevanceTypeStr}会场`,
    });
    if (cardList?.length === 0) {
      Toast.show({
        content: `没有可转发的${info.relevanceType === "STORE" ? "店铺" : "商品"}，请再看看别的吧~`,
        maskClickable: false,
        maskStyle: { zIndex: 9999 },
      });
      return;
    }
    if (platform.isH5) {
      actId &&
        setSharePosterData(
          await sharePosterParams4H5B2R({
            contentId: actId,
            shareBizType: info?.relevanceType,
            shareMainTitle: info?.sharePageInfoConfig?.shareTitle,
            shareSubTitle: info?.sharePageInfoConfig?.shareSubTitle,
            sourceScene: "IncentiveActivity",
          }),
        );
      setCustomEditData({
        contentType: "B2R",
        contentId: actId,
        shareBizType: info?.relevanceType,
        shareMainTitle: info?.sharePageInfoConfig?.shareTitle,
        shareSubTitle: info?.sharePageInfoConfig?.shareSubTitle,
        sourceScene: "IncentiveActivity",
      });
      setShowPoster(true);
    } else {
      shareB2RPosterAction({
        actId,
        bizType: info.relevanceType,
        shareMainTitle: info?.sharePageInfoConfig?.shareTitle || "",
        shareSubTitle: info?.sharePageInfoConfig?.shareSubTitle || "",
        sourceScene: "IncentiveActivity",
      });
    }
  };

  useEffect(() => {
    if (!info) {
      return;
    }
    if (timeId) {
      clearInterval(timeId);
    }
    setTimeId(
      setInterval(() => {
        // 活动状态 [NOT_BEGIN-未开始, ADVANCE-预告中，BEGIN-已开始，END-已结束，CANCEL-已作废，DRAFT-草稿，AWAITING_DRAW-待开奖 ]
        let obj = countDown(
          ["NOT_BEGIN", "ADVANCE"].includes(info.aggStatus) ? info.startTime : info.endTime,
        );
        setCountDownObj(obj);
      }, 1000),
    );
  }, [info]);

  const onClosePoster = () => {
    setShowPoster(false);
    document.body.style.overflow = "auto";
  };

  const getInfo = async () => {
    if (!actId) {
      return;
    }
    setLoading(true);
    const data = await queryDetailServ({
      actId,
    });
    let relevanceTypeStr =
      data?.relevanceType === "PRODUCT"
        ? "商品"
        : data?.relevanceType === "STORE"
          ? "店铺"
          : "档期";
    let trackData = {
      page_name: "激励活动详情",
      previous_page_name: search.get("page_name") || "",
      domain: "激励活动",
      resource_type: relevanceTypeStr,
      resource_content: relevanceTypeStr,
      resource_id: search.get("actId"),
    };
    setInfo({
      ...(data || {}),
      actSellRebateRuleInfos: data?.actSellRebateRuleInfos?.[0],
      trackData,
    });
    onShareAppMessageByB2R({
      contentType: "B2R",
      contentId: actId,
      shareBizType: data?.relevanceType,
      shareMainTitle: data?.shareTitle,
      shareSubTitle: data?.shareSubTitle,
    });
    setLoading(false);
    // 埋点
    setTrackData(trackData);
    track.track("$pageview", trackData);
  };

  const toCustomerListPage = () => {
    // http://fe-static.akcstable.com/v2/analysis/shop/crank?shopCode=xxxxx
    // http://h5shop-stable.akucun.com/#/analysis/shop/crank?shopCode=xxxxx
    if (!info?.ownerId) {
      return;
    }
    if (platform.isH5) {
      navigate2H5({
        path: "/#/analysis/shop/crank",
        query: `shopCode=${info?.ownerId}`,
      });
    } else {
      navigate2H5({
        path: `${process.env.REACT_APP_PAGE_URL_4_APP}/v2/analysis/shop/crank`,
        query: `shopCode=${info?.ownerId}`,
      });
    }
  };

  useEffect(() => {
    getInfo();
    if (addressId) {
      onAddAddress();
      setTabActiveIndex(1);
    } else if (tabIndex) {
      setTabActiveIndex(tabIndex);
    }
  }, []);

  useActivate(() => {
    onShareAppMessageByB2R({
      contentType: "B2R",
      contentId: actId,
      shareBizType: info.relevanceType,
      shareMainTitle: info.shareTitle,
      shareSubTitle: info.shareSubTitle,
    });
  });

  return (
    <>
      <div className={styles.page}>
        {info?.bizType === "GIFT_RETURN" && info?.aggStatus !== "ADVANCE" && (
          <>
            <div className={styles["page__tab"]}>
              {tabList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`${styles["tab"]} ${
                      tabActiveIndex === index && styles["tab__active"]
                    }`}
                    onClick={() => changeTab(index)}
                  >
                    {item.label}
                  </div>
                );
              })}
            </div>
            {tabActiveIndex === 0 ? (
              <ToExChangeTip bizId={actId} callback={() => setTabActiveIndex(1)} />
            ) : (
              <></>
            )}
          </>
        )}
        <Follow pageName="rewardDetail" />
        <div ref={PageScrollRef} className={styles["page__scroll"]}>
          <div className={styles["scroll__body"]}>
            {info.actId && (
              <>
                <div className={styles["header"]}>
                  <div className={styles["activity-status"]}>
                    <div className={styles["activity-status__left"]}>
                      {["ADVANCE", "AWAITING_DRAW", "END"].includes(info?.aggStatus) && (
                        <div className={styles["status"]}>
                          活动{info?.aggStatus === "ADVANCE" ? "预热中" : "已结束"}
                        </div>
                      )}
                    </div>
                    <div className={styles["activity-status__right"]}>
                      {["NOT_BEGIN", "ADVANCE", "BEGIN"].includes(info?.aggStatus) && (
                        <div className={styles["right__count-down"]}>
                          <span>距活动{info.aggStatus === "BEGIN" ? "结束" : "开始"}</span>
                          {countDownDom()}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* {info?.actSellBatePartakeInfo?.thresholdIndicatorType ===
                    "SHOP_NEW_USER_QUANTITY" &&
                    info?.ownerType === "MERCHANT_SHOP" &&
                    info?.ownerId && (
                      <div className={styles["customer-list-wrap"]}>
                        <div
                          className={styles["customer-list"]}
                          onClick={() => toCustomerListPage()}
                        >
                          顾客列表
                          <img
                            className={styles["icon"]}
                            src="https://akim-oss.aikucun.com/mshop/0b4b0a9324ceb632c5a7796d2c98dca05e05ceae_1725000693338_61.png"
                          />
                        </div>
                      </div>
                    )} */}

                  <div className={styles["activity-name-wrap"]}>
                    {info.brandLogos.length > 0 && (
                      <div className={styles["logo-list"]}>
                        {info.brandLogos.slice(0, 3).map((item, index) => {
                          return (
                            <img
                              className={styles["item"]}
                              key={index}
                              style={{ marginLeft: `${pxtorem(index === 0 ? 0 : -10)}` }}
                              src={item}
                            />
                          );
                        })}
                      </div>
                    )}
                    <h3 className={`${styles["activity-name"]} `}>{info.name}</h3>
                  </div>

                  <p className={`${styles["activity-desc"]} ${styles["text-ellipsis1"]}`}>
                    {info.actDesc}
                  </p>
                </div>

                {tabActiveIndex === 0 && (
                  <div className={styles["main"]}>
                    <div className={styles["main__body"]}>
                      {info?.bizType === "FULL_RETURN" && <ADMF info={info} />}
                      {info?.bizType === "GIFT_RETURN" && <ADMZ info={info} />}
                      {info?.bizType === "RANK" && <PK info={info} />}
                      {info.startTime && info.endTime && (
                        <div className={styles["draw-time"]}>
                          <h3>活动时间：</h3>
                          <p>
                            {moment(Number(info.startTime)).format("YYYY.MM.DD HH:mm")}-
                            {moment(Number(info.endTime)).format("YYYY.MM.DD HH:mm")}
                          </p>
                        </div>
                      )}
                      {info?.actSellRebateRuleInfos?.drawTime && (
                        <div className={styles["draw-time"]}>
                          <h3>开奖时间：</h3>
                          <p>
                            预计
                            {moment(Number(info.actSellRebateRuleInfos.drawTime)).format(
                              "YYYY.MM.DD",
                            )}
                          </p>
                        </div>
                      )}
                      {info?.bizType === "GIFT_RETURN" &&
                        info?.actSellBatePartakeInfo?.redemptionDeadlineTime && (
                          <div className={styles["draw-time"]}>
                            <h3>兑奖截止：</h3>
                            <p>
                              {moment(
                                Number(info.actSellBatePartakeInfo.redemptionDeadlineTime),
                              ).format("YYYY.MM.DD HH:mm:ss")}
                            </p>
                          </div>
                        )}
                      {info?.ruleDescList && (
                        <div className={styles["rule-info"]}>
                          <h3>活动规则：</h3>
                          <div
                            className={`${styles["info__list"]} ${showAllRuleDesc && styles["info__list__all"]}`}
                          >
                            {info.ruleDescList}
                          </div>
                          <div
                            className={`${styles["show-more"]} ${
                              showAllRuleDesc && styles["show-more-all"]
                            }`}
                            onClick={(e) => setShowAllRuleDesc(!showAllRuleDesc)}
                          >
                            {showAllRuleDesc ? "收起" : "展开"}全部
                          </div>
                        </div>
                      )}
                    </div>
                    {info?.aggStatus !== "END" && (
                      <ResultList
                        relevanceType={info.relevanceType}
                        setCardList={setCardList}
                        trackData={info.trackData}
                      />
                    )}
                  </div>
                )}
                {tabActiveIndex === 1 && (
                  <div className={styles["main_tab1"]}>
                    <QueryWinning info={info} />
                  </div>
                )}
              </>
            )}
            {loading && (
              <div className={`${styles.loading} ${styles.loadingFixed}`}>
                <Image src={loadingPng} width={pxtorem(30)}></Image>
              </div>
            )}
          </div>
        </div>
        {info?.actId && tabActiveIndex === 0 && info?.aggStatus !== "END" && (
          <div className={styles["page__footer"]}>
            <div className={styles["footer__btn"]} onClick={(e) => onShare(e)}>
              转发赚钱
            </div>
          </div>
        )}
      </div>
      {/* 海报*/}
      <PosterSwipe
        show={showPoster}
        posterData={sharePosterData}
        customEditData={customEditData}
        shareType="B2R"
        btnConfig={["copy", "edit"]}
        close={onClosePoster}
      />
    </>
  );
};
export default rebateCenter;
