import { ceil } from "lodash";

// 以下经过手动验证可以进行从http协议转为https协议；
const convertDomainList = {
  "akmer.aikucun.com": "http:",
  "mer.oss.aikucun.com": "http:",
  "akim.oss-cn-shanghai.aliyuncs.com": "http:",
  "thirdwx.qlogo.cn": "http:",
  "devdev.oss-cn-shanghai.aliyuncs.com": "http:",
  "devdev.cdn.akucun.com": "http:",
  "devdevnew.oss.aikucun.com": "http:",
  "image-process-service.oss-cn-shanghai.aliyuncs.com": "http:",
};

// http2https；
export const http2https = (val) => {
  if (typeof val !== "string" || !val) {
    return val;
  }
  if (val.match(/^https:\/\//)) {
    return val;
  }
  const matchRes = val.match(/^(http:)\/\/([-.\w]+)\/(.+)/);
  if (matchRes && convertDomainList[matchRes[2]] === matchRes[1]) {
    return `https://${matchRes[2]}/${matchRes[3]}`;
  }
  return val;
};

export const formatImgUrl = (url: any, w: any = 100, h: any = 100, mode = "mfit", format: any) => {
  if (!url) {
    return "";
  }
  if (url.match(/.*?\.gif/i)) {
    return url;
  }
  if (url.includes("?x-oss-process")) {
    return url;
  }
  if (url.includes("&x-oss-process")) {
    return url;
  }
  const dpi = Math.ceil(parseInt(String(window.devicePixelRatio || 2), 10)) || 2;
  if (String(h) === "auto") {
    h = "";
  }
  if (String(w) === "auto") {
    w = "";
  }
  let rw = w && ceil(dpi * w, -2);
  const rh = h && ceil(dpi * h, -2);
  const p: any = [];
  if (rw) {
    rw = Math.min(800, rw);
    p.push(`w_${rw}`);
  }
  if (rh) {
    p.push(`h_${rh}`);
  }
  if (mode === "pad") {
    p.push("limit_0");
  }
  const params = p.join(",");
  if (format) {
    // eslint-disable-next-line no-use-before-define
    if (url.indexOf("?") >= 0) {
      return `${http2https(url)}&x-oss-process=image/format,${format}/resize,m_${mode},${params}`;
    }
    return `${http2https(url)}?x-oss-process=image/format,${format}/resize,m_${mode},${params}`;
  }
  if (url.indexOf("?") >= 0) {
    return `${http2https(url)}&x-oss-process=image/resize,m_${mode},${params}`;
  }
  // eslint-disable-next-line no-use-before-define
  return `${http2https(url)}?x-oss-process=image/resize,m_${mode},${params}`;
};
