import { queryFrontSetting } from '@/api/yearEndBonus';
import { platform } from "@/utils/platform";
import AKJS from "@akc/akjs";
import React, { useEffect, useState } from "react";
import styles from "./activity-banner.module.less";
// 业绩提升小妙招
const ImprovePerformance: React.FC = () => {
  const { isApp } = platform;

  const [list, setList] = useState<any>([]);

  const toDetail = (item) => {
    if (!item?.linkUrl) { return }
    if (isApp) {
      AKJS.ready().then(() => {
        AKJS.action.dispatch("event.page.open", {
          url: item.linkUrl,
        });
      });
    }
  }


  const getFrontSetting = () => {
    queryFrontSetting()
      .then(res => {
        setList(res?.annualBonus2024LinkConfigList || []);
      })
  }

  useEffect(() => {
    getFrontSetting();
  }, [])

  return (
    <div className={styles.component}>
      {
        list?.length > 0 && (
          <>
            <h3 className={styles.component__tit}>业绩提升小妙招</h3>
            <div className={styles.component__con}>
              {
                list.map((item: any, index: number) => (
                  <img
                    className={styles.com__item}
                    key={index}
                    src={item.linkLogoUrl}
                    onClick={() => toDetail(item)}
                  />
                ))
              }
            </div>
          </>
        )
      }
    </div>
  );
}

export default ImprovePerformance;