import loadLeft from "@/assets/load_left.png";
import loadRight from "@/assets/load_right.png";
import loadingPng from "@/base64";
import exposure from "@/utils/expose/exposure";
import { pxtorem } from "@/utils/pxtorem";
import Track from "@/utils/track";
import { Image, InfiniteScroll, SafeArea } from "antd-mobile";
import React, { useEffect, useState } from "react";
import RewardEmpty from "../Empty";
import styles from "./search.module.less";

import { ActivityTaskItem, ActivityTaskParams, CouponItem as CouponItemDs } from "@/api/rewardCenter";
import ActivityTask from "@/pages/rewardCenter/components/activity-task/activity-task";
import { useSearchContext } from "./useSearch";

//加载更多
const InfiniteScrollContent = ({ hasMore, list }: { hasMore?: boolean; list: any[] }) => {
  return (
    <div className={styles.infinite}>
      {hasMore ? (
        <div className={styles.loadingMore}>
          <Image src={loadingPng} width={pxtorem(20)}></Image>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          {list?.length > 0 && (
            <>
              <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadLeft}></Image>
              <div style={{ color: "rgba(204, 204, 204, 1)" }}>已经到底了</div>
              <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadRight}></Image>
            </>
          )}
        </div>
      )}
    </div>
  );
};
interface IProps {
  loading: boolean;
  activeTab: ActivityTaskParams["searchType"];
  list: any[];
  hasMore: boolean;
  loadMore: () => Promise<any>;
  onChange?: (value: ActivityTaskParams["searchType"]) => void;
}

const SearchContent: React.FC<IProps> = ({

  // list
  onChange,
  hasMore,
  loadMore,
}) => {
  const track = Track();
  const {
    list,
    showSearchTag,
    searchTagText,
    showSuggest
  } = useSearchContext();

  const handleTabChange = (value: ActivityTaskParams["searchType"]) => {
    onChange && onChange(value);
  };
  // 埋点曝光元素记录
  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < list.length; i++) {
        let id = "cnt-";
        id += (list[i] as ActivityTaskItem).actId;
        const dom = document.getElementById(id);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
        if (!flag) {
          clearInterval(interval);
        }
      }
    }, 1000);
  }, [list]);

  return (
    <>
      {/* {showSearchTag && <>
        {searchTagText ? <>
          <div className={styles.content}>
            {
              list?.length > 0 ? <>
                {list?.map((item) => <ActivityTask key={item.actCode} data={item} />)}
              </> : <RewardEmpty>抱歉，暂无活动</RewardEmpty>
            }
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0}>
              <InfiniteScrollContent list={list} hasMore={hasMore} />
            </InfiniteScroll>
            <SafeArea position='bottom' />
          </div>
        </> : <RewardEmpty>{"暂无活动"}</RewardEmpty>}
      </>} */}
      <div className={styles.content}>
        {
          list?.length > 0 ? <>
            {list?.map((item) => <ActivityTask key={item.actCode} data={item} backgroundColor="#F7F8F9" />)}
          </> : <RewardEmpty>抱歉，暂无活动</RewardEmpty>
        }
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0}>
          <InfiniteScrollContent list={list} hasMore={hasMore} />
        </InfiniteScroll>
        <SafeArea position='bottom' />
      </div>
    </>
  );
};

export default SearchContent;
