import React from "react";
import styles from "./index.module.less";

const FollowShopAssistant = () => {
  const handleClick = () => {
    const jumpOutUrl = "https://mp.weixin.qq.com/s/07rq1S5IbVgaPamNstYj7w";
    const mchData = { action: "jumpOut", jumpOutUrl: jumpOutUrl };
    const postData = JSON.stringify(mchData);
    parent.postMessage(postData, "https://payapp.weixin.qq.com");
  };

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.title}>关注【店铺服务助手】</div>
        <div className={styles.subtitle}>实时掌握订单物流信息，省心又省力</div>
      </div>
      <button className={styles.button} onClick={handleClick}>
        立即关注
      </button>
    </div>
  );
};

export default FollowShopAssistant;
